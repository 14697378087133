import {Component, effect, Injector, signal, ViewChild, WritableSignal} from '@angular/core';
import {BasePageComponent} from "../../../base-component/base-page/base-page.component";
import {TopicService, TopicWebDto} from "../../../../openapi-generated";
import {createHttpState, HttpStateStatus, HttpStateWrapper} from "../../../services/http-state.service";
import {TopicFormService} from "../topic-form.service";
import {FormGroup} from "@angular/forms";
import {TopicEditForm} from "../create-topic-page/create-topic-page.component";
import {Router} from "@angular/router";
import {ClrForm} from "@clr/angular";
import {TitleService} from "../../../services/title.service";

@Component({
  selector: 'app-edit-topic-page',
  templateUrl: './edit-topic-page.component.html',
  styleUrl: './edit-topic-page.component.scss',
  providers: [
    TopicFormService
  ]
})
export class EditTopicPageComponent extends BasePageComponent {

  readState: WritableSignal<HttpStateWrapper<TopicWebDto>> = signal(createHttpState());
  updateState: WritableSignal<HttpStateWrapper<any>> = signal(createHttpState());
  formGroup?: FormGroup<TopicEditForm>;

  constructor(injector: Injector, private topicService: TopicService, public topicFormService: TopicFormService, private router: Router, private titleService: TitleService) {
    effect(() => {
      if (this.routeParams().topicId && this.readState().status === HttpStateStatus.NOT_STARTED) {
        this.refresh();
      }
    }, {allowSignalWrites: true});
    effect(() => {
      this.titleService.pagePrefix.set(this.topicSummaryState().data?.name || '');
    }, {allowSignalWrites: true});
    super(injector);
  }

  private refresh() {
    this.readState.set(createHttpState());
    this.topicService.getTopic(this.routeParams().topicId!).subscribe(state => {
      this.readState.set(state);
      if (state.isReady) {
        this.formGroup = this.topicFormService.createTopicForm();
        this.topicFormService.loadFromDto(this.formGroup, state.data!);
      }
    });
  }

  save() {
    const dto = this.topicFormService.createTopicWebDto(this.formGroup!, this.readState().data!.id);
    this.formGroup?.disable();
    this.updateState.set(createHttpState());
    this.topicService.updateTopic(dto).subscribe(state => {
      this.updateState.set(state);
      if (state.isReady) {
        this.refresh();
      }
      if (state.isError) {
        this.formGroup?.enable();
      }

    });
  }

  deleteTriggered = false;
  deleteHttpState: WritableSignal<HttpStateWrapper<any>> = signal(createHttpState());

  openDeleteModal() {
    this.deleteTriggered = true;

  }

  doDelete() {
    this.deleteHttpState.set(createHttpState());
    this.topicService.deleteTopic(this.routeParams().topicId!).subscribe(state => {
      this.deleteHttpState.set(state);
      if (state.isReady) {
        this.router.navigateByUrl("topic/list");
      }
    })
  }

  @ViewChild(ClrForm) clrForm: ClrForm | undefined;

  markAsTouched() {
    this.clrForm?.markAsTouched();
  }
}
