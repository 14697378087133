import {Directive, ElementRef, EventEmitter, Output, OnDestroy, Input, OnInit, Optional} from '@angular/core';
import {IntersectionObserverDirective} from "./intersection-observer.directive";

@Directive({
  selector: '[appIntersectionObserved]'
})
export class IntersectionObservedDirective implements OnInit, OnDestroy {

  @Output() inViewport = new EventEmitter<boolean>();

  constructor(private element: ElementRef, private observerDirective: IntersectionObserverDirective) {

  }

  ngOnInit(): void {
    this.observerDirective.register(this.element, (intersecting) => {
      this.inViewport.emit(intersecting);
    });
  }

  ngOnDestroy(): void {
    this.observerDirective.unregister(this.element);
  }
}
