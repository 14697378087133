<button class="btn btn-outline btn-sm" (click)="getLink()"><cds-icon shape="link"></cds-icon>{{message}}<span class="badge no-margin-right">{{ids.length}}</span></button>
<clr-modal [clrModalOpen]="link().isReady || link().isLoading || link().isError" [clrModalClosable]="false">
  <h3 class="modal-title" data-test="link-modal-title">
    @if(link().isReady){
      Your link is ready
    }
    @if(link().isLoading){
      Your link is being prepared
    }
    @if(link().isError){
      Something went wrong preparing your link
    }
  </h3>

  <div class="modal-body">
    @if(link().isReady){
      and it's already copied in your clipboard<br/>
      <a [href]="link().data.link" data-test="download-link">{{ link().data.link }}</a>
    }
    @if(link().isLoading){
      <clr-spinner [clrSmall]="true"></clr-spinner>
    }
  </div>
  <div class="modal-footer">
    <button type="button" data-test="close-modal" class="btn btn-outline" (click)="resetLink()">Close</button>
  </div>
</clr-modal>
