import {effect, Injectable, signal, WritableSignal} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {ActivationStart, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  private pageTitle: WritableSignal<string> = signal('Advaya Search');
  private appTitle = 'Advaya Documents'
  public pagePrefix: WritableSignal<string> = signal('');
  public pageSuffix: WritableSignal<string> = signal('');

  constructor(private angularTitle: Title, private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof ActivationStart) {
        this.pagePrefix.set('');
        this.pageSuffix.set('');
        const routeData = event.snapshot.data;
        this.pageTitle.set(routeData['pageTitle']);
      }
    })


    effect(() => {
      this.angularTitle.setTitle(
          this.display(this.pagePrefix())+
          this.display(this.pageTitle())+
          this.display(this.pageSuffix())+
           this.appTitle);
    });

  }

  private display(l: string) {
    return l ? (l + " - ") : '';
  }
}
