<ng-template #subnav>
  <app-document-navbar></app-document-navbar>
</ng-template>

@if (state().isReady) {
  @if (state().data; as doc) {
    <div class="card">
      <div class="card-header">
        {{ doc.title }}
      </div>
      <div class="card-block">
        Tags:
        <ul>
          @for (tag of doc.tags; track tag) {
            <li>{{ tag }}</li>
          }
        </ul>
      </div>
      <div class="card-block">
        Date: {{ doc.date }}
      </div>
      <div class="card-block">
        Description: {{ doc.description }}
      </div>
    </div>
  }
}
