<clr-combobox-container data-test="tags-input">
  <label data-test="tags-input-label">{{label}}</label>
  <clr-combobox [formControl]="tagsFormControl" clrMulti="true" placeholder="category:value"
                (clrInputChange)="tagChanged($event)">
    <ng-container *clrOptionSelected="let selected">
      <span data-test="tag-selected">{{ selected }}</span>
    </ng-container>
    <clr-options>
      <clr-option [attr.data-test]="'tag-choice-'+tag" *clrOptionItems="let tag of availableTags();" [clrValue]="tag">
        {{ tag }}
      </clr-option>
      <div class="clr-combobox-option"><span><em>You can add an new tag like <b>category:value</b></em></span>
      </div>
    </clr-options>
  </clr-combobox>
  <clr-control-helper *ngIf="helper" data-test="tags-input-helper">{{helper}}</clr-control-helper>
</clr-combobox-container>
