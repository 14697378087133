import {Component, effect, Injector, signal, ViewChild} from '@angular/core';
import {BasePageComponent} from "../../../base-component/base-page/base-page.component";
import {ListTopicRequest, ListTopicResponse, TopicService, TopicWebDto} from "../../../../openapi-generated";
import {PaginatedDataProviderService} from "../../../services/paginated-data-provider.service";
import {ClrDatagridPagination, ClrDatagridStateInterface} from "@clr/angular";
import {Clipboard} from "@angular/cdk/clipboard";
import {config} from '../../../config'
import {FormControl} from "@angular/forms";
import {debounceTime, distinctUntilChanged, filter, map, merge, Subject, tap} from "rxjs";


@Component({
  selector: 'app-topic-list-page',
  templateUrl: './topic-list-page.component.html',
  styleUrl: './topic-list-page.component.scss'
})
export class TopicListPageComponent extends BasePageComponent {
  dataProvider!: PaginatedDataProviderService<ListTopicResponse, TopicWebDto, ListTopicRequest>;

  refreshSubject = new Subject<boolean>();

  constructor(injector: Injector, topicService: TopicService, public clipboard: Clipboard) {
    super(injector);

    const timeouts: any[] = [];

    effect(() => {
      if (this.displayEmailCopyNotification()) {
        while (timeouts.length) {
          clearTimeout(timeouts.pop());
        }
        timeouts.push(setTimeout(() => this.displayEmailCopyNotification.set(''), 4000));
      }
    });

    this.dataProvider = new PaginatedDataProviderService({
      fetch: (r: ListTopicRequest) => {
        return topicService.listTopics(r);
      },
      extractDataArray: r => r.topics,
      extractTotalCount: result => result.totalCount
    });


    // Distinct until changed before the debounce, otherwise it might setLoading, and then discard the event, hence never removing the loading state
    const searchTermObservable = this.topicSearchFormControl.valueChanges.pipe(filter(v => !!v), distinctUntilChanged(), tap(it => this.dataProvider.setLoading()), debounceTime(500), map(it => true));
    const emptySearchObservable = this.topicSearchFormControl.valueChanges.pipe(filter(v => !v),map(it => true));
    // queueMicrotask to make sure the value is set in the formControl before firing
    merge(searchTermObservable, emptySearchObservable, this.refreshSubject.asObservable()).subscribe(resetPagination => queueMicrotask(() => this.refresh(resetPagination)));
  }

  private makeRequest() {
    return {
      pagination: {
        pageNumber: this.paginationViewChild.currentPage,
        pageSize: this.paginationViewChild.pageSize
      },
      searchTerm: this.topicSearchFormControl.value || ''
    };
  }

  private refresh(resetPagination: boolean) {
    if (resetPagination) {
      this.paginationViewChild.currentPage = 1;
    }
    this.dataProvider.loadData(this.makeRequest());
  }

  queueRefresh() {
    this.refreshSubject.next(false);
  }


  displayEmailCopyNotification = signal('');

  protected readonly config = config;
  topicSearchFormControl: FormControl<string | null> = new FormControl<string>('');

  copyEmailLink(mailbox: string) {
    let fullEmail = mailbox + '@' + config.emailDomain;
    this.clipboard.copy(fullEmail);
    this.displayEmailCopyNotification.set(fullEmail);
  }
}
