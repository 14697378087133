import {Component, effect, Injector, signal, WritableSignal} from '@angular/core';
import {BasePageComponent} from "../../../base-component/base-page/base-page.component";
import {FormArray, FormControl, FormGroup, isFormGroup, Validators} from "@angular/forms";
import {DocumentService, DocumentUploadRequest, MandatoryTags, TagsService} from "../../../../openapi-generated";
import {Router} from "@angular/router";
import {createHttpState, HttpStateStatus, HttpStateWrapper} from "../../../services/http-state.service";
import {TitleService} from "../../../services/title.service";


interface MandatoryTagForm {
  tagCategory: FormControl<string>,
  tagValue: FormControl<string>
}

interface CreateDocumentForm {
  files: FormControl<FileList | null>,
  mandatoryTags: FormArray<FormGroup<MandatoryTagForm>>,
  additionalTags: FormControl<string[]>
}


@Component({
  selector: 'app-topic-add-document-page',
  templateUrl: './topic-add-document-page.component.html',
  styleUrl: './topic-add-document-page.component.scss'
})
export class TopicAddDocumentPageComponent extends BasePageComponent {
  form?: FormGroup<CreateDocumentForm> = undefined;

  submitHttpState: WritableSignal<HttpStateWrapper<any>> = signal(createHttpState());

  mandatoryTags: WritableSignal<HttpStateWrapper<MandatoryTags>> = signal(createHttpState());

  constructor(injector: Injector, private documentService: DocumentService, private router: Router, private tagsService: TagsService, private titleService: TitleService) {
    effect(() => {
      if (this.routeParams().topicId && this.mandatoryTags().status === HttpStateStatus.NOT_STARTED) {
        this.refresh();
      }
    }, {allowSignalWrites: true});
    effect(() => {
      this.titleService.pagePrefix.set(this.topicSummaryState().data?.name || '');
    }, {allowSignalWrites: true});
    super(injector);
  }


  private refresh() {
    this.form = undefined;
    this.mandatoryTags.set(createHttpState());
    this.tagsService.getMandatoryTags().subscribe(state => {
      this.mandatoryTags.set(state);
      if(state.isReady) {
        const mandatoryTagsFormArray = new FormArray<FormGroup<MandatoryTagForm>>([]);
        this.mandatoryTags().data!.tags.forEach(tag => {
          mandatoryTagsFormArray.push(
            new FormGroup<MandatoryTagForm>({
              tagCategory: new FormControl<string>(tag.category, {nonNullable: true}),
              tagValue: new FormControl<string>('', {nonNullable: true, validators: Validators.required})
            }));
        });
        this.form = new FormGroup<CreateDocumentForm>({
          'files': new FormControl<FileList | null>(null, Validators.required),
          'mandatoryTags': mandatoryTagsFormArray,
          'additionalTags': new FormControl<string[]>([], {nonNullable: true})
        });
      }
    });
  }

  async submit() {
    this.submitHttpState.set(createHttpState());
    const fileList: FileList = this.form!.controls.files.value!;
    const request: DocumentUploadRequest[] = [];
    const formValue = this.form!.value;
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const base64 = await this.fileToBase64(file);
      request.push({
        topicId: this.topicSummaryState().data!.id,
        base64content: base64,
        filename: file.name,
        mime: file.type,
        tags: [...(formValue.additionalTags||[]), ...formValue.mandatoryTags!.map(mandatoryTag => mandatoryTag.tagCategory + ":" + mandatoryTag.tagValue)]
      });
    }
    this.documentService.uploadDocument(request).subscribe(httpState => {
      this.submitHttpState.set(httpState);
      if (httpState.isReady) {
        this.form = undefined;
        this.router.navigateByUrl("/topic/" + this.topicSummaryState().data?.id + "/document");
      }
    });
  }

  fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString().split(',')[1]
        || '');
      reader.onerror = error => reject(error);

    });
  }

  protected readonly isFormGroup = isFormGroup;
}
