import {effect, Inject, Injectable, InjectionToken, signal, WritableSignal} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {debounceTime, distinctUntilChanged, filter, map, merge, skipWhile, Subject, tap} from "rxjs";
import {PaginatedDataProviderService} from "../../services/paginated-data-provider.service";
import {DocumentTablePreferences, PreferencesService, TableIdForPrefs} from "../../services/preferences.service";
import {
  DocumentFormatEnum,
  DocumentReadStatusService,
  DocumentWebDto,
  InboxRequest,
  SearchCriteria
} from "../../../openapi-generated";
import {ActivatedRoute, Router} from "@angular/router";
import {TitleService} from "../../services/title.service";
import {ClrDatagridStateInterface} from "@clr/angular";

export interface DocumentListRequest {
  pagination: {
    pageNumber: number
    pageSize: number
  }
  searchCriteria: SearchCriteria
}

export const TABLE_ID_FOR_PREFS = new InjectionToken<TableIdForPrefs>('TABLE_ID_FOR_PREFS');


// Define a provider for this service on the page component level
@Injectable()
export class DocumentDatagridService {


  dataProvider!: PaginatedDataProviderService<any, any, any>;

  filterFormGroup = new FormGroup<{
    topicId: FormControl<string|null>,
    searchTerm: FormControl<string>,
    tags: FormControl<string[]>
  }>({
    topicId: new FormControl<string>(''),
    searchTerm: new FormControl<string>('', {nonNullable: true}),
    tags: new FormControl<string[]>([], {nonNullable: true})
  });

  constructor(prefs: PreferencesService, private router: Router,
              private route: ActivatedRoute, private titleService: TitleService, private documentReadStatusService: DocumentReadStatusService, @Inject(TABLE_ID_FOR_PREFS) private tableIdForPrefs: TableIdForPrefs) {
    this.pdfStorageEffect();
    this.tablePrefs = prefs.fetchDocumentTablePreferences(this.tableIdForPrefs);
    this.route.queryParamMap.subscribe(queryParamMap => {
      const stateEncoded = queryParamMap.get('state');
      if (stateEncoded) {
        const inboxRequest: InboxRequest = JSON.parse(atob(stateEncoded)) as InboxRequest;
        if (stateEncoded !== this.lastSerializedRequest) {

          this.filterFormGroup.setValue({
            ...(inboxRequest.searchCriteria as any)
          }, {emitEvent: false});
          this.loadingFromUrl = true;
          this.tablePrefs.pageSize = inboxRequest.pagination.pageSize;
          this.currentPage = inboxRequest.pagination.pageNumber;
          setTimeout(() => {
            this.changeFromUrlSubject.next();
          })
        }
      }
    });
  }

// Tags
  toggleTag(tag: string) {
    if (this.isTagSelected(tag)) {
      this.removeTag(tag);
    } else {
      this.addTag(tag);
    }
  }


  isTagSelected(tag: string): boolean {
    return this.extractTags().indexOf(tag) !== -1;
  }

  addTag(tag: string) {
    if (this.extractTags().indexOf(tag) === -1) {
      const currentValue: string[] = this.filterFormGroup.controls.tags.value || [];
      this.filterFormGroup.controls.tags.setValue([...currentValue, tag]);
    }
  }

  removeTag(tag: string) {
    if (this.extractTags().indexOf(tag) !== -1) {
      const currentValue: string[] = this.filterFormGroup.controls.tags.value || [];
      this.filterFormGroup.controls.tags.setValue(currentValue.filter(v => v !== tag));
    }
  }

  extractTags() {
    return (this.filterFormGroup.controls.tags.value || []);
  }

  // End tags

  // Placeholder helper
  hasCriteria() {
    return this.extractTags() && this.extractTags().length || this.filterFormGroup.value.searchTerm;
  }

  // Data loading
  tablePrefs: DocumentTablePreferences;
  currentPage: number = 1;
  lastSerializedRequest: string = '';
  loadingFromUrl = false;
  changeFromUrlSubject: Subject<void> = new Subject();

  refreshSubject = new Subject<boolean>();

  registerRefresh(dataProvider: PaginatedDataProviderService<any, any, any>) {
    this.dataProvider = dataProvider;
    // Distinct until changed before the debounce, otherwise it might setLoading, and then discard the event, hence never removing the loading state
    // skipWhile we are loading from the url to avoid double calls
    const searchTermObservable = this.filterFormGroup.controls.searchTerm.valueChanges.pipe(filter(v => !!v), distinctUntilChanged(), tap(it => dataProvider.setLoading()), debounceTime(500), map(it => true), skipWhile(() => this.loadingFromUrl));
    const emptySearchObservable = this.filterFormGroup.controls.searchTerm.valueChanges.pipe(filter(v => !v), map(it => true), skipWhile(() => this.loadingFromUrl));
    const tagsObservable = this.filterFormGroup.controls.tags.valueChanges.pipe(map(it => true), skipWhile(() => this.loadingFromUrl));
    const changeFromUrlObservable = this.changeFromUrlSubject.pipe(map(it => false));
    // queueMicrotask to make sure the value is set in the formControl before firing
    merge(searchTermObservable, tagsObservable, emptySearchObservable, this.refreshSubject.asObservable().pipe(skipWhile(() => this.loadingFromUrl)), changeFromUrlObservable).subscribe(resetPagination => queueMicrotask(() => {
      this.lastSerializedRequest = this.makeSerializedRequest();
      this.loadingFromUrl = false;
      this.refresh(resetPagination);
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {state: this.lastSerializedRequest},
        queryParamsHandling: "merge"
      })
    }));
  }

  public refresh(resetPagination: boolean) {
    if (resetPagination) {
      this.currentPage = 1;
    }
    this.dataProvider.loadData(this.makeRequest());
  }


  queueRefresh($event?: ClrDatagridStateInterface<any>) {
    if ($event) {
      this.paginationChanged($event.page);
    }
    this.refreshSubject.next(false);
  }

  private makeSerializedRequest() {
    // Make it deterministic so as not to push new items on the history, that breaks navigation back
    return btoa(JSON.stringify(this.makeRequest()));
  }

  paginationChanged($event: { from?: number; to?: number; size?: number; current?: number } | undefined) {
    if ($event && !this.loadingFromUrl) {
      this.tablePrefs.pageSize = $event.size!;
      this.currentPage = $event.current!;
    }
  }

  public makeSearchCriteria(): SearchCriteria {
    return {
      topicId: this.filterFormGroup.value.topicId || '',
      tags: this.filterFormGroup.value.tags || [],
      searchTerm: this.filterFormGroup.value.searchTerm || ''
    };
  }

  makeRequest(): DocumentListRequest {
    const searchCriteria = this.makeSearchCriteria();
    let request = {
      pagination: {
        pageNumber: this.currentPage,
        pageSize: this.tablePrefs.pageSize
      },
      searchCriteria: searchCriteria
    };
    let suffix = "Page " + this.currentPage + "(" + this.tablePrefs.pageSize + ")";
    if (searchCriteria.searchTerm) {
      suffix += ' - searching on "' + searchCriteria.searchTerm + '"'
    }
    if (searchCriteria.tags && searchCriteria.tags.length) {
      suffix += " - " + searchCriteria.tags.join(', ');
    }
    this.titleService.pageSuffix.set(suffix);
    return request
  }

  // End data loading


  // Reading status
  markAllAsRead(items: DocumentWebDto[]) {
    this.selected$.set([]);
    this.markIdsAsRead(items.map(it => it.documentId));
  }

  markAllAsUnread(items: DocumentWebDto[]) {
    this.selected$.set([]);
    this.markIdsAsUnread(items.map(it => it.documentId));
  }

  markAsRead(id: string) {
    this.markIdsAsRead([id]);
  }

  markAsUnread(id: string) {
    this.markIdsAsUnread([id]);
  }

  private markIdsAsRead(ids: string[]) {
    this.dataProvider.loading.set(true);
    this.documentReadStatusService.markAsRead(ids).subscribe(s => {
      if (s.isReady) {
        this.queueRefresh();
      }
      if (s.isError) {
        this.dataProvider.loading.set(false);
      }
    });
  }

  private markIdsAsUnread(ids: string[]) {
    this.dataProvider.loading.set(true);
    this.documentReadStatusService.markAsUnread(ids).subscribe(s => {
      if (s.isReady) {
        this.queueRefresh();
      }
      if (s.isError) {
        this.dataProvider.loading.set(false);
      }
    });
  }

  // Selection
  pdfStorageIds: WritableSignal<string[]> = signal([]);
  private pdfStorageEffect() {
    effect(() => {
      let ids = this.selected$().filter(it => it?.referenceInStorage && it?.referenceInStorage[DocumentFormatEnum.Pdf]).map(it => it.referenceInStorage![DocumentFormatEnum.Pdf]);
      this.pdfStorageIds.set(ids);
    }, {
      allowSignalWrites: true
    });
  }

  selected$: WritableSignal<DocumentWebDto[]> = signal([]);

  set selected(s) {
    this.selected$.set(s);
  }

  get selected() {
    return this.selected$();
  }
}
