import {provideRouter, RouterModule, Routes, withComponentInputBinding, withHashLocation} from "@angular/router";
import {NgModule} from "@angular/core";
import {LoadExtensionPageComponent} from "./pages/load-extension-page/load-extension-page.component";
import {AskPageComponent} from "./pages/ask-page/ask-page.component";
import {AdminPageComponent} from "./pages/admin-page/admin-page.component";
import {HomePageComponent} from "./pages/home-page/home-page.component";
import {CreateTopicPageComponent} from "./pages/topic-pages/create-topic-page/create-topic-page.component";
import {TopicListPageComponent} from "./pages/topic-pages/topic-list-page/topic-list-page.component";
import {TopicDocumentsPageComponent} from "./pages/topic-pages/topic-documents-page/topic-documents-page.component";
import {DocumentDetailPageComponent} from "./pages/document-pages/document-detail-page/document-detail-page.component";
import {DocumentReadPageComponent} from "./pages/document-pages/document-read-page/document-read-page.component";
import {
  TopicAddDocumentPageComponent
} from "./pages/topic-pages/topic-add-document-page/topic-add-document-page.component";
import {EditTopicPageComponent} from "./pages/topic-pages/edit-topic-page/edit-topic-page.component";
import {BookmarkListPageComponent} from "./pages/other-pages/bookmark-list-page/bookmark-list-page.component";
import {InboxPageComponent} from "./pages/other-pages/inbox-page/inbox-page.component";
import {
  DocumentNoteTakingPageComponent
} from "./pages/document-pages/document-note-taking-page/document-note-taking-page.component";

export const routes: Routes = [
  {
    component: HomePageComponent,
    path: 'home',
    data: {
      pageTitle: 'Home'
    }
  },
  {
    component: CreateTopicPageComponent,
    path: 'topic/create',
    data: {
      pageTitle: 'Create topic'
    }
  },
  {
    component: TopicListPageComponent,
    path: 'topic/list',
    data: {
      pageTitle: 'Topic List'
    }
  },
  {
    component: TopicDocumentsPageComponent,
    path: 'topic/:topicId/document',
    data: {
      pageTitle: 'Topic Documents'
    }
  },
  {
    component: DocumentDetailPageComponent,
    path: 'topic/:topicId/document/:documentId/detail',
    data: {
      pageTitle: 'Document Detail'
    }
  },
  {
    path: 'topic/:topicId/document/:documentId',
    redirectTo: 'topic/:topicId/document/:documentId/note-taking-mode'
  },
  {
    component: DocumentReadPageComponent,
    path: 'topic/:topicId/document/:documentId/read-mode',
    data: {
      pageTitle: 'Read Document'
    }
  },
  {
    component: DocumentNoteTakingPageComponent,
    path: 'topic/:topicId/document/:documentId/note-taking-mode',
    data: {
      pageTitle: 'Notes on Document'
    }
  },
  {
    component: EditTopicPageComponent,
    path: 'topic/:topicId/edit',
    data: {
      pageTitle: 'Edit Topic'
    }
  },
  {
    component: TopicAddDocumentPageComponent,
    path: 'topic/:topicId/add-document',
    data: {
      pageTitle: 'Add Document(s)'
    }
  },
  {
    component: BookmarkListPageComponent,
    path: 'bookmarks',
    data: {
      pageTitle: 'Bookmarks'
    }
  },
  {
    component: InboxPageComponent,
    path: 'inbox',
    data: {
      pageTitle: 'Inbox'
    }
  },
  {
    component: LoadExtensionPageComponent,
    path: 'load-extension',
    data: {
      pageTitle: 'Load Chrome Extension'
    }
  },
  {
    component: AskPageComponent,
    path: 'ask',
    data: {
      pageTitle: 'Ask a Question'
    }
  },
  {
    component: AdminPageComponent,
    path: 'admin',
    data: {
      pageTitle: 'Admin'
    }
  },
  {
    path: '',
    redirectTo: '/topic/list',
    pathMatch: 'full',
  }
];

@NgModule({
  providers: [
    provideRouter(routes, withComponentInputBinding(), withHashLocation()),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
