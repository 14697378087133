import {
  AfterViewInit,
  Component,
  Injector,
  OnDestroy,
  OnInit,
  signal,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  WritableSignal
} from '@angular/core';
import {TemplatePortal} from "@angular/cdk/portal";
import {PortalService} from "../../services/portal.service";
import {ActivatedRoute, Router} from "@angular/router";
import {createHttpState, HttpStateWrapper} from "../../services/http-state.service";
import {DocumentService, DocumentWebDto, TopicService, TopicSummaryResponse} from "../../../openapi-generated";
import {ClrDatagridPagination} from "@clr/angular";

export interface IdParams {
  topicId?: string,
  documentId?: string
}

@Component({
  template: ''
})
export abstract class BasePageComponent implements AfterViewInit, OnInit, OnDestroy {

  @ViewChild('subnav') subnavTemplatePortalContent: TemplateRef<unknown> | undefined;
  private subnavTemplatePortal!: TemplatePortal<unknown>;

  @ViewChild('mainnav') mainnavTemplatePortalContent: TemplateRef<unknown> | undefined;
  private mainnavTemplatePortal!: TemplatePortal<unknown>;

  @ViewChild('notifications') notificationsTemplatePortalContent: TemplateRef<unknown> | undefined;
  private notificationsTemplatePortal!: TemplatePortal<unknown>;

  @ViewChild("pagination")
  paginationViewChild!: ClrDatagridPagination;

  private p: PortalService;
  private _viewContainerRef: ViewContainerRef
  private _router: Router;
  private _route: ActivatedRoute;
  private _topicService: TopicService;
  private _documentService: DocumentService;

  constructor(protected injector: Injector) {
    // Looking up thru injector so as not to force each implementation to wire lots of dependencies
    this.p = injector.get(PortalService);
    this._viewContainerRef = injector.get(ViewContainerRef);
    this._router = injector.get(Router);
    this._route = injector.get(ActivatedRoute);
    this._topicService = injector.get(TopicService);
    this._documentService = injector.get(DocumentService);
  }

  ngAfterViewInit() {
    if (this.subnavTemplatePortalContent) {
      this.subnavTemplatePortal = new TemplatePortal(this.subnavTemplatePortalContent, this._viewContainerRef);
      this.p.subnav.set(this.subnavTemplatePortal);
    }
    if (this.mainnavTemplatePortalContent) {
      this.mainnavTemplatePortal = new TemplatePortal(this.mainnavTemplatePortalContent, this._viewContainerRef);
      this.p.mainnav.set(this.mainnavTemplatePortal);
    }
    if (this.notificationsTemplatePortalContent) {
      this.notificationsTemplatePortal = new TemplatePortal(this.notificationsTemplatePortalContent, this._viewContainerRef);
      this.p.notifications.set(this.notificationsTemplatePortal);
    }
  }


  topicSummaryState: WritableSignal<HttpStateWrapper<TopicSummaryResponse>> = signal(createHttpState());
  documentSummaryState: WritableSignal<HttpStateWrapper<DocumentWebDto>> = signal(createHttpState());

  routeParams: WritableSignal<IdParams> = signal({});

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      this.routeParams.set(params);
      this.flushSummaries();
    });
  }

  flushSummaries() {
    const topicId = this.routeParams()['topicId'];
    if (topicId) {
      this.topicSummaryState.set(createHttpState());
      this._topicService.getTopicSummary(topicId).subscribe(state => this.topicSummaryState.set(state));
    }
    const documentId = this.routeParams()['documentId'];
    if (documentId) {
      this.documentSummaryState.set(createHttpState());
      this._documentService.fetchDocumentDto(documentId).subscribe(state => this.documentSummaryState.set(state));
    }
  }

  ngOnDestroy() {
    if (this.subnavTemplatePortal) {
      this.p.subnav.set(null);
    }
    if (this.mainnavTemplatePortal) {
      this.p.mainnav.set(null);
    }
    if (this.notificationsTemplatePortal) {
      this.p.notifications.set(null);
    }
  }

  navigateOnClickFromTableRow(args: string[], $event: MouseEvent) {
    // Walk the ancestors till the clr-dg-row
    let currentElement: HTMLElement | null = $event.target as HTMLElement;
    const tagPath = [];

    while (currentElement && !(currentElement.tagName.toLowerCase() === "clr-dg-row")) {
      tagPath.push(currentElement.tagName.toLowerCase());
      if(currentElement.attributes.getNamedItem("data-clickable")){
        return;
      }
      currentElement = currentElement.parentElement;
    }
    // We don't navigate if the clicked element is one with typically a click handler
    if (!this.intersects(["a", "button", "label", "input"], tagPath)) {
      this._router.navigate(args);
    }
  }

  intersects<T>(arr1: T[], arr2: T[]): boolean {
    const set2 = new Set(arr2);
    return arr1.filter(item => set2.has(item)).length > 0;
  }

}

