<div class="card">
  <div class="card-header">
    Task management
  </div>
  <div class="card-block">
    <button class="btn btn-outline" (click)="retryAll()" [clrLoading]="state().isLoading" data-test="retry-all-tasks">
      Retry all tasks
    </button>
  </div>
  <div class="card-block">
    <button class="btn btn-outline" (click)="refreshAllTrieveMetadata()" [clrLoading]="trieveRefreshState().isLoading" data-test="refresh-trieve-metadata">
      Refresh Trieve Metadata
    </button>
  </div>
</div>

<div class="card">
  <div class="card-header">
    Error management
  </div>
  <div class="card-footer">
    <button class="btn btn-danger" (click)="triggerFrontendError()" data-test="trigger-frontend-error">Trigger frontend error</button>
    <button class="btn btn-danger" (click)="triggerBackendError()" data-test="trigger-backend-log-error">Trigger backend log error</button>
    <button class="btn btn-danger" (click)="triggerBackendException()" data-test="trigger-backend-exception">Trigger backend exception</button>
    <button class="btn btn-warning" (click)="triggerBackendWarning()" data-test="trigger-backend-log-warning">Trigger backend log warning</button>
  </div>
</div>
