<ng-template #subnav>
  <nav class="subnav" data-test="subnav">
    <ul class="nav">
      <li class="nav-item">
        <a class="nav-link " routerLinkActive="active" routerLink="/topic/list" data-test="navbar-all-topics">All
          topics</a>
        <cds-icon shape="angle" direction="right"></cds-icon>
        <a class="nav-link" routerLinkActive="active" routerLink="/topic/create" data-test="navbar-create-topic">
          <cds-icon shape="plus"></cds-icon>
          Create new topic</a>
      </li>
    </ul>
  </nav>
</ng-template>

@if (formGroup) {
  <form [formGroup]="formGroup" clrForm clrLayout="horizontal">
    <div class="card">
      <div class="card-header" data-test="new-topic-header">
        Create new topic <em *ngIf="formGroup.value.basics?.name">{{ formGroup.value.basics?.name }}</em>
      </div>
      <div class="card-block">
        <app-topic-basics-form data-test="topic-basic-form"
                               [appFormGroup]="formGroup.controls.basics"></app-topic-basics-form>
      </div>
      <ng-container *ngFor="let companyFormGroup of this.formGroup.controls.companies.controls; index as i">
        <div class="card-block" *ngIf="companyFormGroup.enabled">
          <app-topic-company-form [attr.data-test]="'topic-company-form-'+i"
                                  [appFormGroup]="companyFormGroup"></app-topic-company-form>
          <div class="clr-row clr-form-control">
            <div class="clr-col-12">
              <button type="button" class="btn btn-sm btn-danger-outline" [attr.data-test]="'remove-company-'+i"
                      (click)="topicFormService.deleteCompany(companyFormGroup)"
                      *ngIf="topicFormService.moreThanOneCompany()">
                <cds-icon shape="times"></cds-icon>
                Remove this company
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="card-block">
        <button type="button" class="btn btn-sm" (click)="topicFormService.addOneMoreCompany(formGroup)"
                data-test="add-a-company"
        >
          <cds-icon shape="plus"></cds-icon>
          Add one more company
        </button>
      </div>
      <div class="card-footer">
        <button [clrLoading]="submitState().isLoading" class="btn btn-primary" data-test="create-topic-button"
                [disabled]="formGroup.pristine || formGroup.invalid" type="button" (click)="createTopic()"
                (mouseenter)="markAsTouched()">
          <cds-icon shape="warning-standard" *ngIf="formGroup.invalid"></cds-icon>
          Create
        </button>
        <a [clrLoading]="submitState().isLoading" class="btn btn-outline" type="button"
           [routerLink]="'/topic/'+topicSummaryState().data?.id+'/document'" data-test="dont-create-topic-button">Don't
          create
        </a>
      </div>
    </div>
  </form>
} @else {
  <div class="center-container">
    <clr-spinner></clr-spinner>
  </div>
}
