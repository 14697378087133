import {Directive, ElementRef, OnDestroy} from '@angular/core';

@Directive({
  selector: '[appIntersectionObserver]'
})
export class IntersectionObserverDirective implements OnDestroy {

  observer: IntersectionObserver;

  constructor(private element: ElementRef) {
    this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          let cb = this.callbacks.get(entry.target.id);
          if (cb) {
            cb(entry.isIntersecting);
          }
        });
      },
      {
        // as soon as a pixel enters the horizontal line at the middle of the viewport
        threshold: 0,
        root: this.element.nativeElement,
        rootMargin: '-45% 0% -45% 0%'
      }
    );
  }

  ngOnDestroy(): void {
    // Clean up by disconnecting the observer
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  callbacks = new Map<string, (b: boolean) => void>;

  register(el: ElementRef, cb: (intersecting: boolean) => void) {
    if (!el.nativeElement.id) {
      throw new Error("Element must have an id to be used with the appIntersectionObservedDirective");
    }
    this.callbacks.set(el.nativeElement.id, cb);
    this.observer.observe(el.nativeElement);
  }

  unregister(el: ElementRef) {
    this.observer.unobserve(el.nativeElement);
    this.callbacks.delete(el.nativeElement.id);
  }
}
