import {Component, Input, signal} from '@angular/core';
import {createHttpState} from "../../services/http-state.service";
import {PdfLinkService} from "../../../openapi-generated";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: 'app-get-link',
  templateUrl: './get-link.component.html',
  styleUrl: './get-link.component.scss'
})
export class GetLinkComponent {

  @Input()
  ids!: string[]

  @Input()
  message: string = 'Get Link to PDF with all documents'

  link = signal(createHttpState());

  constructor(private pdfLinkService: PdfLinkService, private clipboard: Clipboard) {
  }


  getLink() {
    this.pdfLinkService.createPdfLink({
      pdfStorageIds: this.ids
    }).subscribe(data => {
      this.link.set(data);
      if (data.isReady) {
        const l = data.data?.link!;
        this.clipboard.copy(l);

      }
    });
  }

  resetLink() {
    this.link.set(createHttpState());
  }

}
