import {Injectable, signal, WritableSignal} from '@angular/core';
import {WhoamiResponse} from "../../openapi-generated";

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  whoami: WritableSignal<WhoamiResponse|null>  = signal(null);

  constructor() { }
}
