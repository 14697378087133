/**
 * Advaya Search Web Contract
 * Advaya Search Web Contract
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TaskStatusEnum = 'PENDING' | 'DONE' | 'ERROR';

export const TaskStatusEnum = {
    Pending: 'PENDING' as TaskStatusEnum,
    Done: 'DONE' as TaskStatusEnum,
    Error: 'ERROR' as TaskStatusEnum
};

