import {Injectable} from '@angular/core';
import {DatePipe} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(private datePipe: DatePipe) {
  }

  dtoDateToDatePickerDate(dtoDate: string | null | undefined): string {
    if (!dtoDate) {
      return "";
    }
    let date = new Date(dtoDate);
    date.setHours(12)
    return (this.datePipe.transform(date, 'dd/MM/YYYY'))!;
  }

  datePickerDateToDtoDate(datePickerDate: string | null | undefined): string | undefined {
    if (!datePickerDate) {
      return undefined;
    }
    return this.convertToIsoString(datePickerDate);
  }


  private convertToIsoString(dateString: string): string {
    const [day, month, year] = dateString.split('/');
    let date = new Date(Number(year), Number(month)-1, Number(day));
    date.setHours(12);
    return this.datePipe.transform(date, 'YYYY-MM-dd')!;
  }
}
