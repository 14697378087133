import {Component, OnInit, signal, WritableSignal} from '@angular/core';

import {
  bookmarkIcon, cameraIcon, checkIcon,
  ClarityIcons, clockIcon,
  copyIcon, cursorHandOpenIcon, detailsIcon, displayIcon, fileGroupIcon, hourglassIcon, inboxIcon, libraryIcon,
  linkIcon, noteIcon,
  pencilIcon,
  plusIcon,
  refreshIcon,
  searchIcon,
  timesIcon, undoIcon, unknownStatusIcon,
  userIcon, viewListIcon, warningStandardIcon
} from '@cds/core/icon';
import {WhoamiResponse, WhoamiService} from "../openapi-generated";
import {HttpStateService} from "./services/http-state.service";

import * as Sentry from "@sentry/angular";
import {PortalService} from "./services/portal.service";
import {IdentityService} from "./services/identity.service";
import {TitleService} from "./services/title.service";


ClarityIcons.addIcons(searchIcon, userIcon, plusIcon, pencilIcon, copyIcon, timesIcon, linkIcon, refreshIcon, undoIcon, unknownStatusIcon, libraryIcon, displayIcon, detailsIcon, viewListIcon, fileGroupIcon, bookmarkIcon, inboxIcon, warningStandardIcon, noteIcon, cameraIcon, checkIcon);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [
    PortalService
  ]
})
export class AppComponent implements OnInit {
  title = 'advaya-search';

  extensionLoaded: WritableSignal<boolean> = signal(true);

  constructor(private whoamiService: WhoamiService, public httpStateService: HttpStateService, public portalService: PortalService, public identityService: IdentityService, private titleService: TitleService) {
    setTimeout(() => {
      console.log("Advaya search extension content script loaded", window.document.body.classList);
      if (window.document.body.classList.contains('advaya-extension-loaded')) {
        this.extensionLoaded.set(true);
      } else {
        this.extensionLoaded.set(false);
      }
    }, 5000)
  }

  ngOnInit(): void {
    this.whoamiService.whoami().subscribe(it => {
        if (it.isReady) {
          if (it.data!.sentryEnabled) {
            Sentry.init({
              dsn: it.data!.sentryDsn
            })
          }
          this.identityService.whoami.set(it.data!);
        }
      }
    );
  }
}
