<ng-container [formGroup]="appFormGroup">
  @if (appFormGroup.value.name) {
    <h5>Company: {{ appFormGroup.value.name }}</h5>
  } @else {
    <h5>New Company</h5>
  }
  <div class="clr-row">
    <div class="clr-col-6">
      <clr-input-container>
        <label>Company Name</label>
        <input data-test="company-name" clrInput formControlName="name"/>
        <clr-control-error *clrIfError="'required'">Name Required</clr-control-error>
      </clr-input-container>

      <clr-combobox-container>
        <label>Tickers</label>
        <clr-combobox data-test="company-tickers" formControlName="tickers" clrMulti="true" placeholder="GOOGL"
                      (clrInputChange)="tickerChanged($event)">
          <ng-container *clrOptionSelected="let selected">
            <span data-test="ticker-selected">{{ selected }}</span>
          </ng-container>
          <clr-options>
            <clr-option [attr.data-test]="'ticker-option-'+ticker" *clrOptionItems="let ticker of availableTickers()"
                        [clrValue]="ticker">
              {{ ticker }}
            </clr-option>
            <div class="clr-combobox-option"><span><em>You can add an new ticker</em></span></div>
          </clr-options>
        </clr-combobox>
        <clr-control-helper>These tickers will be used to lookup documents</clr-control-helper>
      </clr-combobox-container>

      <app-tags-input data-test="company-tags-input" [tagsFormControl]="appFormGroup.controls.defaultTags"
                      label="Default tags"
                      helper="These tags will be added to each document ingested for this company"></app-tags-input>

    </div>
    <div class="clr-col-6">
      <ng-container formGroupName="sec">
        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox formControlName="active"/>
            <label data-test="sec-active">SEC filings automated retrieval active</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>
        @if (appFormGroup.value.sec?.active) {
          <clr-combobox-container>
            <label style="margin-top: 0">
              <button class="btn btn-sm" type="button" data-test="open-find-sec-modal"
                      (click)="openSecModal(secModal)">Find SEC id(s)
              </button>
            </label>
            <clr-combobox data-test="cik-input" formControlName="cik" clrMulti="true">
              <ng-container *clrOptionSelected="let selected">
        <span data-test="cik-selected">
        @if (selected?.name) {
          {{ selected?.name }}:
        }{{ selected?.cik }}
          </span>
              </ng-container>
              <clr-options>
              </clr-options>
            </clr-combobox>
            <clr-control-error>At least one CIK is required</clr-control-error>
          </clr-combobox-container>
          <clr-date-container>
            <label>Start date to pull SEC filings</label>
            <input type="date" data-test="sec-start-date" autocomplete="off" clrDate formControlName="startDate"/>
            <clr-control-error>Date is required</clr-control-error>
          </clr-date-container>
        }
      </ng-container>
      <ng-container formGroupName="bernstein">
        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox formControlName="active"/>
            <label data-test="bernstein-active">Bernstein Research automated retrieval active</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>
        @if (appFormGroup.value.bernstein?.active) {
          <clr-date-container>
            <label>Start date to pull from Bernstein</label>
            <input type="date" data-test="bernstein-start-date" autocomplete="off" clrDate
                   formControlName="startDate"/>
            <clr-control-error>Date is required</clr-control-error>
          </clr-date-container>
          <clr-input-container>
            <label>Company URL</label>
            <input data-test="bernstein-company-url" clrInput formControlName="companyUrl"/>
            <clr-control-helper>Mandatory<br/>e.g. https://www.bernsteinresearch.com/brweb/ticker.aspx?id=783#/
            </clr-control-helper>
            <clr-control-error>URL is required</clr-control-error>
          </clr-input-container>
        }
      </ng-container>
      <ng-container formGroupName="thirdBridge">
        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox formControlName="active"/>
            <label data-test="third-bridge-active">ThirdBridge automated retrieval active</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>
        @if (appFormGroup.value.thirdBridge?.active) {
          <clr-date-container>
            <label>Start date to pull from ThirdBridge</label>
            <input type="date" data-test="third-bridge-start-date" autocomplete="off" clrDate
                   formControlName="startDate"/>
            <clr-control-error *clrIfError="'required'">Date is required</clr-control-error>
          </clr-date-container>
          <clr-input-container>
            <label>Company URL</label>
            <input data-test="third-bridge-company-url" clrInput formControlName="companyUrl"/>
            <clr-control-helper>e.g.
              https://forum.thirdbridge.com/en/search?q=c6e650cee7f30a81e80047acb17986e7c81ceb4c<br/>If not provided, a
              search on the company name is performed
            </clr-control-helper>
          </clr-input-container>
        }
      </ng-container>
      <ng-container formGroupName="tegus">
        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox formControlName="active"/>
            <label data-test="tegus-active">Tegus automated retrieval active</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>
        @if (appFormGroup.value.tegus?.active) {
          <clr-date-container>
            <label>Start date to pull from tegus</label>
            <input type="date" data-test="tegus-start-date" autocomplete="off" clrDate
                   formControlName="startDate"/>
            <clr-control-error>Date is required</clr-control-error>
          </clr-date-container>
          <clr-input-container>
            <label>Company URL</label>
            <input data-test="tegus-company-url" clrInput formControlName="companyUrl"/>
            <!--            <clr-control-helper>Mandatory<br/>e.g. https://www.tegusresearch.com/brweb/ticker.aspx?id=783#/</clr-control-helper>-->
            <clr-control-error>URL is required</clr-control-error>
          </clr-input-container>
        }
      </ng-container>
    </div>
  </div>

  <clr-modal #secModal>
    <h3 class="modal-title" data-test="link-modal-title">
      Add SEC identifiers
    </h3>

    <div class="modal-body">
      <form clrForm (ngSubmit)="secSearch()">
        <clr-input-container>
          <label>Search query</label>
          <input data-test="sec-search-input" clrInput placeholder="Company ticker" name="name" [(ngModel)]="secInput"
                 autocomplete="off"/>
        </clr-input-container>

      </form>
      <ng-container formGroupName="sec">
        <clr-combobox-container>
          <label>SEC identifiers (CIK)</label>
          <clr-combobox data-test="cik-input-in-modal" formControlName="cik" clrMulti="true">
            <ng-container *clrOptionSelected="let selected">
        <span data-test="cik-selected">
            @if (selected?.name) {
              {{ selected?.name }}:
            }{{ selected?.cik }}
        </span>
            </ng-container>
            <clr-options>
            </clr-options>
          </clr-combobox>
        </clr-combobox-container>
      </ng-container>
      @if (secState().isLoading) {
        <div style="text-align: center">
          <clr-spinner></clr-spinner>
        </div>
      }
      @if (secState().isReady) {
        <table class="table middle-align-cells" data-test="cik-list-table">
          <tr>
            <th>Company name</th>
            <th>Ticker(s)</th>
            <th>CIK</th>
            <th></th>
          </tr>
          @for (item of secState().data!.results; track item.cik) {
            <tr [attr.data-test]="'cik-'+$index">
              <td data-test="company-name">{{ item.name }}</td>
              <td data-test="company-tickers">{{ item.tickers }}</td>
              <td>
                {{ item.cik }}
              </td>
              <td>
                <button type="button" class="btn btn-sm" (click)="addCik(item)" [attr.data-test]="'add-cik-'+item.cik">
                  Add
                </button>
              </td>
            </tr>
          }
        </table>
      }

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="secModal.close()">Done</button>
    </div>
  </clr-modal>

</ng-container>
