<nav class="subnav" data-test="subnav">
  <ul class="nav">
    <li class="nav-item" data-test="subnav-breadcrumb">
      <a class="nav-link " routerLinkActive="active" routerLink="/topic/list" data-test="navbar-all-topics">All
        topics</a>
      <cds-icon shape="angle" direction="right"></cds-icon>
      @if (topicSummaryState().isReady) {
        <a class="nav-link"
           routerLink="/topic/{{routeParams().topicId}}/document" data-test="navbar-current-topic">
          {{ topicSummaryState().data!.name }}</a>
      }
      @if (topicSummaryState().isLoading) {
        <cds-icon shape="loading"></cds-icon>
      }
      <cds-icon shape="angle" direction="right"></cds-icon>
      <a class="nav-link"
         routerLink="/topic/{{routeParams().topicId}}/document" data-test="navbar-current-topic-documents">
        <cds-icon shape="view-list"></cds-icon>
        Documents</a>
      <cds-icon shape="angle" direction="right"></cds-icon>
      @if (documentSummaryState().isReady) {
        {{ documentSummaryState().data!.title }}
      }
      @if (documentSummaryState().isLoading) {
        <cds-icon shape="loading"></cds-icon>
      }
      <cds-icon shape="angle" direction="right"></cds-icon>
      <a class="nav-link only-show-active" routerLinkActive="active" data-test="navbar-current-topic-detail"
         routerLink="/topic/{{routeParams().topicId}}/document/{{routeParams().documentId}}/detail">
        <cds-icon shape="details"></cds-icon>
        Details</a>
      <a class="nav-link only-show-active" routerLinkActive="active" data-test="navbar-current-topic-read-mode"
         routerLink="/topic/{{routeParams().topicId}}/document/{{routeParams().documentId}}/read-mode">
        <cds-icon shape="display"></cds-icon>
        Reading mode</a>
      <a class="nav-link only-show-active" routerLinkActive="active" data-test="navbar-current-topic-read-mode"
         routerLink="/topic/{{routeParams().topicId}}/document/{{routeParams().documentId}}/note-taking-mode">
        <cds-icon shape="note"></cds-icon>
        Note-taking mode</a>
    </li>
    <li class="filler"></li>
    <li class="nav-item" data-test="subnav-siblings">
      <a class="nav-link" routerLinkActive="active" data-test="navbar-current-topic-detail"
         routerLink="/topic/{{routeParams().topicId}}/document/{{routeParams().documentId}}/detail">
        <cds-icon shape="details"></cds-icon>
        Details</a>
      <a class="nav-link" routerLinkActive="active" data-test="navbar-current-topic-read-mode"
         routerLink="/topic/{{routeParams().topicId}}/document/{{routeParams().documentId}}/read-mode">
        <cds-icon shape="display"></cds-icon>
        Reading mode</a>
      <a class="nav-link" routerLinkActive="active" data-test="navbar-current-topic-read-mode"
         routerLink="/topic/{{routeParams().topicId}}/document/{{routeParams().documentId}}/note-taking-mode">
        <cds-icon shape="note"></cds-icon>
        Note taking mode</a>
    </li>
  </ul>
</nav>

