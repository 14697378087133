<ng-template #subnav>
  <app-topic-navbar></app-topic-navbar>
</ng-template>

@if (formGroup) {
  <form [formGroup]="formGroup" clrForm clrLayout="horizontal">
    <div class="card">
      <div class="card-header" data-test="edit-topic-header">
        Edit topic <em>{{ formGroup.value.basics?.name }}</em>
      </div>
      <div class="card-block">
        <app-topic-basics-form data-test="topic-basic-form"
                               [appFormGroup]="formGroup.controls.basics"></app-topic-basics-form>
      </div>
      <ng-container *ngFor="let companyFormGroup of this.formGroup.controls.companies.controls; index as i">
        <div class="card-block" *ngIf="companyFormGroup.enabled">
          <app-topic-company-form [attr.data-test]="'topic-company-form-'+i"
                                  [appFormGroup]="companyFormGroup"></app-topic-company-form>
          <div class="clr-row clr-form-control">
            <div class="clr-col-12">
              <button type="button" class="btn btn-sm btn-danger-outline" [attr.data-test]="'remove-company-'+i"
                      (click)="topicFormService.deleteCompany(companyFormGroup)"
                      *ngIf="topicFormService.moreThanOneCompany()">
                <cds-icon shape="times"></cds-icon>
                Remove this company
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="card-block">
        <button type="button" class="btn btn-sm" (click)="topicFormService.addOneMoreCompany(formGroup!)"
                data-test="add-a-company"
        >
          <cds-icon shape="plus"></cds-icon>
          Add one more company
        </button>
      </div>
      <div class="card-footer">
        <button [clrLoading]="updateState().isLoading || readState().isLoading" class="btn btn-primary"
                data-test="save-button"
                [disabled]="formGroup.invalid || formGroup.pristine" type="button" (click)="save()" (mouseenter)="markAsTouched()">
          <cds-icon shape="warning-standard" *ngIf="formGroup.invalid"></cds-icon>
          Save
        </button>
        <a [clrLoading]="updateState().isLoading || readState().isLoading" class="btn btn-outline" type="button"
           [routerLink]="'/topic/'+topicSummaryState().data?.id+'/document'"
           data-test="dont-save-button">Don't save
        </a>
        <div class="clr-flex-grow-1"></div>
        <button type="button" class="btn btn-danger" data-test="delete-button" (click)="openDeleteModal()">Delete this
          topic
        </button>
      </div>
    </div>
  </form>
  <clr-modal [(clrModalOpen)]="deleteTriggered" [clrModalClosable]="false" data-test="delete-modal">
    <h3 class="modal-title">Do you really want to delete this topic ?</h3>
    <div class="modal-body" data-test="delete-modal-body">
      {{ topicSummaryState().data?.name }}
    </div>
    <div class="modal-footer">
      <button [disabled]="deleteHttpState().isLoading" type="button" class="btn btn-outline"
              (click)="deleteTriggered = false" data-test="dont-delete">No, don't delete
      </button>
      <button [clrLoading]="deleteHttpState().isLoading" type="button"
              class="btn btn-primary btn-danger"
              (click)="doDelete();" data-test="do-delete">
        Yes,
        delete this topic
      </button>
    </div>
  </clr-modal>

} @else {
  <div class="center-container">
    <clr-spinner></clr-spinner>
  </div>
}

