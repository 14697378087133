import {Component, Input, OnInit, signal, WritableSignal} from '@angular/core';
import {FormControl} from "@angular/forms";
import {TagsService} from "../../../openapi-generated";

@Component({
  selector: 'app-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrl: './tags-input.component.scss'
})
export class TagsInputComponent implements OnInit {

  @Input()
  tagsFormControl!: FormControl<string[]>;

  @Input()
  label: string = 'Tags';

  @Input()
  helper: string = '';

  availableTags_: string[] = [];
  availableTags: WritableSignal<any[]> = signal(this.availableTags_);

  constructor(private tagsService: TagsService) {
  }

  ngOnInit(): void {
    this.tagsService.getAllTagChoices().subscribe(state => {
      if (state.isReady) {
        this.availableTags_ = state.data?.tags!;
        this.availableTags.set(this.availableTags_);
      }
    })
  }


  tagChanged($event: string) {
    if ($event.indexOf(":") !== -1) {
      this.availableTags.set([
        $event,
        ...this.availableTags_
      ]);
    } else {
      this.availableTags.set(this.availableTags_);
    }
  }

}
