import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {v4 as uuidv4} from "uuid";
import {CompanyDefinition, SecCompanyDescription, TopicWebDto} from "../../../openapi-generated";
import {
  TopicEditForm,
  TopicEditFormBasics,
  TopicEditFormCompany,
  TopicEditFormSecRetrieval,
  TopidEditFormCompanyGenericRetrieval
} from "./create-topic-page/create-topic-page.component";
import {DateService} from "../../services/date.service";
import {Injectable, signal} from "@angular/core";

@Injectable()
export class TopicFormService {

  constructor(private dateService: DateService) {
  }

  moreThanOneCompany = signal(false);

  createTopicForm() {
    const formGroup = new FormGroup<TopicEditForm>({
      basics: new FormGroup<TopicEditFormBasics>({
        name: new FormControl<string>('', {nonNullable: true, validators: Validators.required}),
        mailbox: new FormControl<string>('', {nonNullable: true, validators: Validators.required}),
        defaultTags: new FormControl<string[]>([], {nonNullable: true})
      }),
      companies: new FormArray<FormGroup<TopicEditFormCompany>>(
        [
          this.createCompanyFormGroup()
        ]
      ),
      finalize: new FormGroup({})
    });
    formGroup.valueChanges.subscribe(v => {
      this.moreThanOneCompany.set(formGroup.controls.companies.controls!.filter((it: any) => it.enabled === true).length > 1);
    })
    return formGroup;
  }

  createCompanyFormGroup() {
    let bernsteinActive = new FormControl<boolean>(false, {nonNullable: true});
    let secActive = new FormControl<boolean>(false, {nonNullable: true});
    let tegusActive = new FormControl<boolean>(false, {nonNullable: true});
    let thirdBridgeActive = new FormControl<boolean>(false, {nonNullable: true});
    let formGroup = new FormGroup<TopicEditFormCompany>(
      {
        identifier: new FormControl(uuidv4(), {nonNullable: true}),
        name: new FormControl<string>('', {nonNullable: true, validators: Validators.required}),
        defaultTags: new FormControl<string[]>([], {nonNullable: true}),
        tickers: new FormControl<string[]>([], {nonNullable: true}),
        sec: new FormGroup<TopicEditFormSecRetrieval>({
          cik: new FormControl<SecCompanyDescription[]>([], {
            nonNullable: true,
            validators: (control) => {
              if (control.value && control.value.length) {
                return null;
              }
              return {empty: true};
            }
          }),
          active: secActive,
          startDate: new FormControl<string>('', {
            nonNullable: true,
            validators: Validators.required
          }),
        }),
        bernstein: new FormGroup<TopidEditFormCompanyGenericRetrieval>({
          companyUrl: new FormControl<string>('', {
            nonNullable: true, validators: Validators.required
          }),
          active: bernsteinActive,
          startDate: new FormControl<string>('', {
            nonNullable: true,
            validators: Validators.required
          })
        }),
        tegus: new FormGroup<TopidEditFormCompanyGenericRetrieval>({
          companyUrl: new FormControl<string>('', {nonNullable: true}),
          active: tegusActive,
          startDate: new FormControl<string>('', {
            nonNullable: true,
            validators: Validators.required
          })
        }),
        thirdBridge: new FormGroup<TopidEditFormCompanyGenericRetrieval>({
          companyUrl: new FormControl<string>('', {nonNullable: true}),
          active: thirdBridgeActive,
          startDate: new FormControl<string>('', {
            nonNullable: true,
            validators: Validators.required
          })
        })
      }
    );
    formGroup.controls.sec.controls.active.valueChanges.subscribe(v => {
      if (v) {
        formGroup.controls.sec.controls.startDate.enable();
        formGroup.controls.sec.controls.cik.enable();
      } else {
        formGroup.controls.sec.controls.startDate.disable();
        formGroup.controls.sec.controls.cik.disable();
      }
    });
    this.activateSiblingsBasedOnState(formGroup.controls.sec.controls.active);
    this.activateSiblingsBasedOnState(formGroup.controls.tegus.controls.active);
    this.activateSiblingsBasedOnState(formGroup.controls.bernstein.controls.active);
    this.activateSiblingsBasedOnState(formGroup.controls.thirdBridge.controls.active);
    formGroup.reset();
    return formGroup;
  }

  private activateSiblingsBasedOnState(activeFormControl: FormControl<boolean>) {
    activeFormControl.valueChanges.subscribe(active => {
      let controls = (activeFormControl.parent! as FormGroup).controls;
      for (let siblingKey of Object.keys(controls)) {
        let sibling = controls[siblingKey];
        if (sibling !== activeFormControl) {
          active?sibling.enable():sibling.disable();
        }
      }
    });
  }


  addOneMoreCompany(formGroup: FormGroup<TopicEditForm>) {
    formGroup.controls.companies.push(this.createCompanyFormGroup());
  }

  deleteCompany(companyFormGroup: FormGroup<TopicEditFormCompany>) {
    companyFormGroup.disable();
  }

  createTopicWebDto(formGroup: FormGroup<TopicEditForm>, id: string) {
    const v = formGroup.value;
    return {
      name: v.basics!.name!,
      companyDefinitions: formGroup.controls.companies.controls.filter(it => it.enabled).map(companyForm => {
        const c = companyForm.value;
        const r: CompanyDefinition = {
          defaultTags: c.defaultTags!,
          tickers: c.tickers!,
          name: c.name!,
          secRetrievalDefinition: {
            active: c.sec?.active || false,
            cik: c.sec?.active?c.sec!.cik!.map(c => c.cik):[],
            startDate: this.dateService.datePickerDateToDtoDate(c.sec!.startDate)
          },
          thirdBridgeRetrievalDefinition: {
            active: c.thirdBridge?.active || false,
            companyUrl: c.thirdBridge?.companyUrl,
            startDate: this.dateService.datePickerDateToDtoDate(c.thirdBridge!.startDate)
          },
          bernsteinRetrievalDefinition: {
            active: c.bernstein?.active || false,
            companyUrl: c.bernstein?.companyUrl,
            startDate: this.dateService.datePickerDateToDtoDate(c.bernstein!.startDate)
          },
          tegusRetrievalDefinition: {
            active: c.tegus?.active || false,
            companyUrl: c.tegus?.companyUrl,
            startDate: this.dateService.datePickerDateToDtoDate(c.tegus!.startDate)
          }
        };
        return r;
      }),
      defaultTags: v.basics?.defaultTags!,
      id: id,
      mailbox: v.basics!.mailbox!,
    };
  }

  loadFromDto(formGroup: FormGroup<TopicEditForm>, topicWebDto: TopicWebDto) {
    while (formGroup.controls.companies.length < topicWebDto.companyDefinitions.length) {
      this.addOneMoreCompany(formGroup);
    }
    formGroup.setValue({
      basics: {
        defaultTags: topicWebDto.defaultTags,
        mailbox: topicWebDto.mailbox,
        name: topicWebDto.name
      },
      companies: topicWebDto.companyDefinitions!.map(c => {
        return {
          defaultTags: c.defaultTags,
          identifier: uuidv4(),
          name: c.name,
          tickers: c.tickers,
          sec: {
            active: c.secRetrievalDefinition?.active || false,
            startDate: this.dateService.dtoDateToDatePickerDate(c.secRetrievalDefinition?.startDate),
            cik: c.secRetrievalDefinition?.cik!.map(it => {
              return {
                name: '',
                cik: it
              }
            }) || [],
          },
          bernstein: {
            active: c.bernsteinRetrievalDefinition?.active || false,
            companyUrl: c.bernsteinRetrievalDefinition?.companyUrl || '',
            startDate: this.dateService.dtoDateToDatePickerDate(c.bernsteinRetrievalDefinition?.startDate)
          },
          tegus: {
            active: c.tegusRetrievalDefinition?.active || false,
            companyUrl: c.tegusRetrievalDefinition?.companyUrl || '',
            startDate: this.dateService.dtoDateToDatePickerDate(c.tegusRetrievalDefinition?.startDate)
          },
          thirdBridge: {
            active: c.thirdBridgeRetrievalDefinition?.active || false,
            companyUrl: c.thirdBridgeRetrievalDefinition?.companyUrl || '',
            startDate: this.dateService.dtoDateToDatePickerDate(c.thirdBridgeRetrievalDefinition?.startDate)
          }

        }
      }),
      finalize: {}
    });
  }

}
