<div class="card no-margin-top">
  <div class="card-header">
    <div class="card-title" data-test="note-view-title">
      @if (currentNote) {
        Edit note on page {{ currentNote.page }}
      } @else {
        Create note on page {{ page }}
      }
    </div>
  </div>
  <div class="card-footer card-block" style="flex-shrink: 0">
    <button class="btn" (click)="addScreenshot()" data-test="add-screenshot">
      <cds-icon shape="camera"></cds-icon>
      Add a screenshot
    </button>
    @if (currentNote) {
      <button class="btn btn-primary" (click)="saveAndClose()" data-test="save-and-close">
        <cds-icon shape="check"></cds-icon>
        Save &amp; close
      </button>
    } @else {
      <button class="btn btn-primary" (click)="saveAndCreate()" data-test="save-and-add-another">
        <cds-icon shape="check"></cds-icon>
        <cds-icon shape="plus"></cds-icon>
        Save &amp; add another note
      </button>
    }
    <div class="clr-flex-fill"></div>
    @if (!currentNote) {
      <button class="btn" (click)="saveAndClose()" data-test="save-and-close">
        <cds-icon shape="check"></cds-icon>
        Save &amp; close
      </button>
    }
    <button class="btn btn-warning-outline" (click)="close()" data-test="dont-save">Don't save</button>
  </div>
  <div class="card-block clr-flex-fill" style="padding-bottom:0;flex-grow:1;display: flex;flex-direction: column">
    <app-editor [appFormControl]="formControl"></app-editor>
  </div>

  @if (takingScreenshot()) {
    <app-take-pdf-screenshot (done)="onScreenshot($event)"></app-take-pdf-screenshot>
  }
</div>
