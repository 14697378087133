import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Editor, Toolbar} from "ngx-editor";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrl: './editor.component.scss',
  encapsulation: ViewEncapsulation.None // To bubble down styles to the editor
})
export class EditorComponent implements OnInit, OnDestroy {

  @Input({required: true})
  appFormControl!: FormControl<string>;

  editor!: Editor;
  toolbar: Toolbar = [
    ['undo', 'redo'],
    [ 'format_clear'],
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['indent', 'outdent'],
    ['horizontal_rule'],
    ['superscript', 'subscript'],
    ['link'],
    ['text_color']
  ];

  ngOnInit(): void {
    this.editor = new Editor({
      history: true
    });
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
