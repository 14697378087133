import {Component, Input, ViewEncapsulation} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-note-content-display',
  templateUrl: './note-content-display.component.html',
  styleUrl: './note-content-display.component.scss',
})
export class NoteContentDisplayComponent {
  @Input({required: true})
  html!: string;

  constructor(public domSanitizer: DomSanitizer){}

}
