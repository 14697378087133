@if (!closed()) {
    <div class="overlay"
         data-test="screenshot-overlay"
         [style.cursor]="'crosshair'"
         (mousedown)="startSelection($event)"
         (mousemove)="mouseMove($event)" (mouseup)="endSelection()">
      @if (highlightInfo(); as h) {
        <div style="position: absolute;border: solid 3px rgba(0,0,0,0.3);background-color: rgba(0,0,0,0.1)"
             [style.top]="h.startY+'px'" [style.left]="h.startX+'px'"
             [style.width]="h.width+'px'" [style.height]="h.height+'px'"></div>
      }
      <div class="menu" style="position: absolute;bottom: 30px; right: 30px">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              Taking a screenshot of the document
            </div>
          </div>
          <div class="card-block">
            Just draw an area over the document to take the screenshot...
          </div>
          <div class="card-footer">
            <button class="btn btn-outline" (click)="cancel()" data-test="cancel-screenshot">Cancel</button>
          </div>
        </div>
      </div>
    </div>
}
