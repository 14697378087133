<ng-container [formGroup]="appFormGroup">
  <div class="clr-row">
    <div class="clr-col-6">
  <clr-input-container>
    <label>Topic Name</label>
    <input clrInput formControlName="name" data-test="topic-name-input"/>
    <clr-control-error *clrIfError="'required'">Name Required</clr-control-error>
  </clr-input-container>

  <clr-input-container>
    <label>Mailbox</label>
    <input clrInput formControlName="mailbox" data-test="topic-name-mailbox"/>
    <clr-control-error *clrIfError="'required'">Mailbox Required</clr-control-error>
    <clr-control-helper>&#64;{{ config.emailDomain}}</clr-control-helper>
  </clr-input-container>

  <app-tags-input data-test="topic-default-tags" [tagsFormControl]="appFormGroup.controls.defaultTags" label="Default tags" helper="These tags will be added to each document ingested in the topic"></app-tags-input>
    </div>
  </div>
</ng-container>
