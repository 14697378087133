import {Component, EventEmitter, Input, OnInit, Output, signal, WritableSignal} from '@angular/core';
import {ScreenshotResult} from "../take-pdf-screenshot/take-pdf-screenshot.component";
import {FormControl} from "@angular/forms";
import {DocumentNote, NoteService} from "../../../../../openapi-generated";
import {v4 as uuidv4} from 'uuid';
import {IdentityService} from "../../../../services/identity.service";
import {createHttpState, HttpStateWrapper} from "../../../../services/http-state.service";

@Component({
    selector: 'app-create-note',
    templateUrl: './edit-note.component.html',
    styleUrl: './edit-note.component.scss'
})
export class EditNoteComponent implements OnInit {

    @Input({required: true})
    documentId!: string

    @Input({required: true})
    page!: number;

    @Input()
    currentNote?: DocumentNote

    state: WritableSignal<HttpStateWrapper<void>> = signal(createHttpState());

    lastCreatedNoteId?: string;

    takingScreenshot: WritableSignal<boolean> = signal(false);
    formControl: FormControl<string> = new FormControl<string>('', {nonNullable: true});
    @Output() done = new EventEmitter<{ lastCreatedNoteId?: string }>();

    constructor(private noteService: NoteService, private identityService: IdentityService) {
    }

    ngOnInit(): void {
        this.lastCreatedNoteId = undefined;
        if (this.currentNote) {
            this.formControl.setValue(this.currentNote?.html)
        }
    }

    addScreenshot() {
        this.takingScreenshot.set(true);
    }

    onScreenshot(result: ScreenshotResult) {
        this.takingScreenshot.set(false);
        if (result.taken) {
            const currentValue = this.formControl.value;
            const imgTag = ` <img src="${result.imageDataUrl}" title="${result.text}" /> `;
            this.formControl.setValue(currentValue + imgTag);
        }
    }

    close() {
        this.done.emit({lastCreatedNoteId: this.lastCreatedNoteId});
    }

    saveAndClose() {
        this.state.set(createHttpState());
        let documentNote = this.createRequest();
        this.noteService.upsertNote(documentNote).subscribe(state => {
            if (state.isReady) {
                this.done.emit({
                    lastCreatedNoteId: documentNote.id
                });
            }
        })
    }

    private createRequest() {
        return {
            documentId: this.documentId,
            html: this.formControl.value,
            id: this.currentNote ? this.currentNote.id : uuidv4(),
            page: this.page,
            authorEmail: this.identityService.whoami()?.email
        };
    }

    saveAndCreate() {
        this.state.set(createHttpState());
        let documentNote = this.createRequest();
        this.noteService.upsertNote(documentNote).subscribe(state => {
            if (state.isReady) {
                this.lastCreatedNoteId = documentNote.id;
                this.reset();
            }
        });
    }

    private reset() {
        this.formControl.reset('');
    }
}
