import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ClarityModule} from "@clr/angular";
import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {ApiModule, Configuration} from "../openapi-generated";
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {DisplayDocumentTextComponent} from "./components/display-document-text/display-document-text.component";
import {DropzoneComponent} from "./components/dropzone/dropzone.component";
import {GetLinkComponent} from "./components/get-link/get-link.component";
import {AskPageComponent} from "./pages/ask-page/ask-page.component";
import {SearchComponent} from "./components/search/search.component";
import {AdminPageComponent} from "./pages/admin-page/admin-page.component";

import * as Sentry from "@sentry/angular";
import {HomePageComponent} from "./pages/home-page/home-page.component";
import {CreateTopicPageComponent} from "./pages/topic-pages/create-topic-page/create-topic-page.component";
import {TopicListPageComponent} from './pages/topic-pages/topic-list-page/topic-list-page.component';
import {TopicDocumentsPageComponent} from './pages/topic-pages/topic-documents-page/topic-documents-page.component';
import {DocumentDetailPageComponent} from './pages/document-pages/document-detail-page/document-detail-page.component';
import {DocumentReadPageComponent} from './pages/document-pages/document-read-page/document-read-page.component';
import {
  TopicAddDocumentPageComponent
} from './pages/topic-pages/topic-add-document-page/topic-add-document-page.component';
import {EditTopicPageComponent} from './pages/topic-pages/edit-topic-page/edit-topic-page.component';
import {PortalModule} from "@angular/cdk/portal";
import {NavbarSearchComponent} from './components/navbar-search/navbar-search.component';
import {TopicNavbarComponent} from './pages/topic-pages/topic-navbar/topic-navbar.component';
import {DocumentNavbarComponent} from './pages/document-pages/document-navbar/document-navbar.component';
import {InboxPageComponent} from './pages/other-pages/inbox-page/inbox-page.component';
import {BookmarkListPageComponent} from './pages/other-pages/bookmark-list-page/bookmark-list-page.component';
import { TopicBasicsFormComponent } from './pages/topic-pages/create-topic-page/topic-basics-form/topic-basics-form.component';
import { TopicCompanyFormComponent } from './pages/topic-pages/create-topic-page/topic-company-form/topic-company-form.component';
import { TagsInputComponent } from './components/tags-input/tags-input.component';

import { registerLocaleData } from '@angular/common';
import localeEnGb from '@angular/common/locales/en-GB';
import {
  DocumentNoteTakingPageComponent
} from "./pages/document-pages/document-note-taking-page/document-note-taking-page.component";
import {IntersectionObserverDirective} from "./viewport/intersection-observer.directive";
import { EditNoteComponent } from './pages/document-pages/document-note-taking-page/edit-note/edit-note.component';
import {NgxEditorModule} from "ngx-editor";
import { EditorComponent } from './pages/document-pages/document-note-taking-page/editor/editor.component';
import { TakePdfScreenshotComponent } from './pages/document-pages/document-note-taking-page/take-pdf-screenshot/take-pdf-screenshot.component';
import { NoteContentDisplayComponent } from './pages/document-pages/note-content-display/note-content-display.component';
import {ObjectDatePipe} from "./pipes/object-date.pipe";
import {IntersectionObservedDirective} from "./viewport/intersection-observed.directive";
import { DocumentPaginationComponent } from './pages/other-pages/document-pagination/document-pagination.component';
import { DocumentDatagridComponent } from './components/document-datagrid/document-datagrid.component';

registerLocaleData(localeEnGb);

@NgModule({
  declarations: [AppComponent,
    AskPageComponent,
    DisplayDocumentTextComponent,
    DropzoneComponent,
    GetLinkComponent,
    SearchComponent,
    HomePageComponent,
    CreateTopicPageComponent,
    AdminPageComponent,
    TopicListPageComponent,
    TopicDocumentsPageComponent,
    DocumentDetailPageComponent,
    DocumentReadPageComponent,
    DocumentNoteTakingPageComponent,
    TopicAddDocumentPageComponent,
    EditTopicPageComponent,
    NavbarSearchComponent,
    TopicNavbarComponent,
    DocumentNavbarComponent,
    InboxPageComponent,
    BookmarkListPageComponent,
    TopicBasicsFormComponent,
    TopicCompanyFormComponent,
    TagsInputComponent,
    IntersectionObserverDirective,
    IntersectionObservedDirective,
    EditNoteComponent,
    EditorComponent,
    TakePdfScreenshotComponent,
    NoteContentDisplayComponent,
    ObjectDatePipe,
    DocumentPaginationComponent,
    DocumentDatagridComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ClarityModule,
    PortalModule,
    AppRoutingModule,
    HttpClientModule,
    NgxEditorModule,
    ApiModule.forRoot(() => {
      return new Configuration({
        basePath: ``,
      });
    }),
    NgxExtendedPdfViewerModule,
    FormsModule,
  ],
  providers: [
    DatePipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
      // useClass: GlobalErrorHandler,
    },
    { provide: LOCALE_ID, useValue: 'en-GB' }
  ]
})
export class AppModule {
}
