<div data-test="drop-container" class="drop-container" (dragenter)="dragContainer.classList.add('dragging')"
     (dragend)="dragContainer.classList.remove('dragging')"
     (dragleave)="dragContainer.classList.remove('dragging')"
     (drop)="onDrop($event);dragContainer.classList.remove('dragging')"
     (dragover)="onDragOver($event)"
     #dragContainer>
  @if (appFormControl.value && appFormControl.value.length) {
    <div style="display: flex;flex-wrap: wrap">
      @for (f of Array.from(appFormControl.value); track f.name; let i = $index) {
        <div class="item-in-dropzone">
          <div class="btn-group">
            <button [attr.data-test]="'file-name-'+i" class="btn btn-sm file-btn" disabled style="cursor:pointer !important;">{{ f.name }}</button>
            <button class="btn btn-icon btn-sm" (click)="remove(f)" [attr.data-test]="'remove-file-'+i">
              <cds-icon shape="times" style="margin-left:0;margin-right:0"></cds-icon>
            </button>
          </div>
        </div>
      }
      <div class="item-in-dropzone">
        <button data-test="remove-all" class="btn btn-sm btn-outline-danger" (click)="removeAll()">
          <cds-icon shape="times"></cds-icon>
          Remove all
        </button>
      </div>
    </div>

  }
  <!-- Display none to avoid weird ExpressionChangedAfterItHasBeenCheckedError -->
  <clr-file-input-container data-test="browse-files" clrButtonLabel="Browse or drop files"
                            [style.display]="(appFormControl.value && appFormControl.value.length)?'none':''">
    <input type="file" [formControl]="appFormControl" multiple required clrFileInput/>
  </clr-file-input-container>

</div>
