import {Component, effect, signal, WritableSignal} from '@angular/core';
import {
  AskResponse,
  AskService,
  ChatProviderEnum,
  DocumentFormatEnum,
  DocumentService,
  DocumentWebDto,
  SearchCriteria
} from "../../../openapi-generated";
import {createHttpState, HttpStateWrapper} from "../../services/http-state.service";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {take} from "rxjs";
import {FormControl} from "@angular/forms";


@Component({
  selector: 'app-ask-page',
  templateUrl: './ask-page.component.html',
  styleUrl: './ask-page.component.scss'
})
export class AskPageComponent {
  state: WritableSignal<HttpStateWrapper<AskResponse>> = signal(createHttpState());

  pdfStorageIds: WritableSignal<string[]> = signal([]);
  searchCriteria: SearchCriteria | undefined;
  documentIds: string[] | undefined;

  documentsState: WritableSignal<HttpStateWrapper<DocumentWebDto[]>> = signal(createHttpState());

  totalCharacters = signal(0);

  providerFormControl: FormControl = new FormControl<ChatProviderEnum>(ChatProviderEnum.Trieve);

  constructor(private askService: AskService, public domSanitizer: DomSanitizer, private r: ActivatedRoute, private documentService: DocumentService) {
    r.params.pipe(take(1)).subscribe(it => {
      if (it['filter']) {
        this.searchCriteria = JSON.parse(atob(it['filter']));
      }
      if (it['documents']) {
        this.documentIds = JSON.parse(atob(it['documents']));
        this.documentService.getAllDocumentDtosById({items: this.documentIds!}).subscribe(state => {
          this.documentsState.set(state);
          if(state.isReady){
            this.totalCharacters.set(state.data!.map(it => it.characterCount||0).reduce((a,b) => a+b));
          }
        });
      }
    });
    effect(() => {
      let ids: string[] = [];
      if (this.state().isReady) {
        ids = this.state().data!.references!.map(ref => ref.documentDto).filter(it => it?.referenceInStorage && it?.referenceInStorage[DocumentFormatEnum.Pdf]).map(it => it!.referenceInStorage![DocumentFormatEnum.Pdf]);
      }
      this.pdfStorageIds.set(ids);
    }, {
      allowSignalWrites: true
    });
  }

  submit(question: string) {
    this.state.set(createHttpState());
    this.askService.ask({
      question: question,
      criteria: this.searchCriteria,
      documents: this.documentIds,
      provider: this.providerFormControl.value
    }).subscribe(state => {
      this.state.set(state);
    })
  }

  protected readonly ChatProviderEnum = ChatProviderEnum;
}
