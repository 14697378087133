/**
 * Advaya Search Web Contract
 * Advaya Search Web Contract
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DocumentFormatEnum = 'TEXT' | 'PDF' | 'HTML' | 'MHTML' | 'YOUTUBE_LINK' | 'SEC_URL';

export const DocumentFormatEnum = {
    Text: 'TEXT' as DocumentFormatEnum,
    Pdf: 'PDF' as DocumentFormatEnum,
    Html: 'HTML' as DocumentFormatEnum,
    Mhtml: 'MHTML' as DocumentFormatEnum,
    YoutubeLink: 'YOUTUBE_LINK' as DocumentFormatEnum,
    SecUrl: 'SEC_URL' as DocumentFormatEnum
};

