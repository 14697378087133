import {Component, SecurityContext, signal} from '@angular/core';
import {DocumentDatagridService} from "./document-datagrid.service";
import {NavigationFromRowService} from "../../services/navigation-from-row.service";
import {createHttpState} from "../../services/http-state.service";
import {DocumentService, DocumentWebDto, SearchCriteria, TaskStatusEnum} from "../../../openapi-generated";
import {Router} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-document-datagrid',
  templateUrl: './document-datagrid.component.html',
  styleUrl: './document-datagrid.component.scss'
})
export class DocumentDatagridComponent {
  deleteTriggered: boolean = false;

  deleteHttpState = signal(createHttpState());

  constructor(public s: DocumentDatagridService, public navigationFromRowService: NavigationFromRowService, private documentService: DocumentService, private router: Router, public domSanitizer: DomSanitizer){}

  deleteSelection() {
    this.deleteHttpState = signal(createHttpState());
    const idsToDelete = this.s.selected$().map(it => it.documentId!);
    this.documentService.deleteDocuments({
      ids: idsToDelete
    }).subscribe(state => {
      this.deleteHttpState.set(state);
      if (state.isReady) {
        this.s.selected$.set([]);
        this.deleteTriggered = false;
        this.s.refresh(true);
      }
    });
  }

  askQuestionsOnThisResultSet() {
    const s: SearchCriteria = this.s.makeSearchCriteria()
    let arg = btoa(JSON.stringify(s));
    this.router.navigate(['ask', {filter: arg}])
  }

  askQuestionsOnSelection() {
    let arg = btoa(JSON.stringify(this.s.selected$().map(doc => doc.documentId)));
    this.router.navigate(['ask', {documents: arg}]);
  }

  retry(id: string) {
    this.s.dataProvider.loading.set(true);
    this.s.dataProvider.setLoading()
    this.documentService.retryTasksForDocument(id).subscribe(state => {
      if(state.isReady){
        this.s.queueRefresh();
      }
      if(state.isError){
        this.s.dataProvider.loading.set(false);
      }
    });
  }

  taskSummary(doc: DocumentWebDto): TaskStatusEnum {
    if (doc.featuresInError!.length > 0) {
      return TaskStatusEnum.Error
    }

    if (!doc.targetFeatures.every(target => doc.availableFeatures.includes(target))) {
      return TaskStatusEnum.Pending
    }
    return TaskStatusEnum.Done;
  }


  protected readonly SecurityContext = SecurityContext;
}
