import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from "@angular/common";

@Pipe({
  name: 'objectDate'
})
export class ObjectDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {
  }

  transform(value: { createdAt?: string, modifiedAt?: string }): string | null {

    if (value.createdAt === value.modifiedAt) {
      return this.formatSingleDate(value.createdAt);
    }
    return this.formatSingleDate(value.createdAt) + " (modified " + this.formatOtherDate(value.createdAt, value.modifiedAt) + ")";
  }

  private formatSingleDate(value?:string) {
    if (this.same('y', value)) {
      return this.datePipe.transform(value, 'MMM d, HH:mm');
    }
    return this.datePipe.transform(value, 'MMM d y, HH:mm');
  }

  private same(format: string, first: string | undefined, second: string | Date = new Date()) {
    const v1 = this.datePipe.transform(first, format);
    const v2 = this.datePipe.transform(first, format);
    return v1 == v2
  }

  private formatOtherDate(createdAt: string | undefined, modifiedAt: string | undefined) {
    if(this.same('shortDate',createdAt, modifiedAt)){
      return 'at '+this.datePipe.transform(modifiedAt, 'HH:mm')
    }
    return 'on '+this.formatSingleDate(modifiedAt);
  }
}
