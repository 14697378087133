import {signal, WritableSignal} from '@angular/core';
import {ClrDatagridStateInterface} from "@clr/angular";
import {createHttpState, HttpStateWrapper} from "./http-state.service";
import {Observable} from "rxjs";


export interface PaginatedDataFunctions<R, T, RT> {
  fetch: (request: RT) => Observable<HttpStateWrapper<R>>;
  extractDataArray: (result: R) => T[];
  extractTotalCount: (result: R) => number;
}


export class PaginatedDataProviderService<R, T, RT> {
  state: WritableSignal<HttpStateWrapper<R>> = signal(createHttpState());
  currentPage: WritableSignal<T[]> = signal([]);
  loading = signal(false);
  total = signal(0);

  constructor(private f: PaginatedDataFunctions<R, T, RT>) {

  }

  loadData(request: RT) {

    this.loading.set(true);
    this.state.set(createHttpState());
    this.f.fetch(request).subscribe(state => {
      this.state.set(state);
      if (state.isReady) {
        this.total.set(this.f.extractTotalCount(state.data!));
        this.currentPage.set(this.f.extractDataArray(state.data!));
        this.loading.set(false);
      }
      if (state.isError) {
        this.loading.set(false);
      }
    });
  }

  setLoading() {
    this.loading.set(true);
  }
}
