import {Injectable} from '@angular/core';

export interface DocumentTablePreferences {
  pageSize: number,
  hiddenColumns: {
    title: boolean,
    date: boolean,
    topic: boolean,
    source: boolean,
    type: boolean,
    tickers: boolean,
    pageCount: boolean,
    notes: boolean,
    readStatus: boolean,
    actions: boolean,
    status: boolean
  }
}

export enum TableIdForPrefs {
  TOPIC_DOCUMENT_LIST = 'topic-document-list',
  INBOX = 'inbox'
}


@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  private localStorage: Storage = window.localStorage;

  constructor() {
  }


  private readonly documentTablePreferences = 'documentTablePreferences';

  fetchDocumentTablePreferences(key: TableIdForPrefs): DocumentTablePreferences {
    let v = this.loadDocumentTablePreferences(key);
    let proxy = this.createRecursiveProxy(v, () => {
      this.saveDocumentTablePreferences(key, v);
    });
    return proxy;
  }


  private loadDocumentTablePreferences(key: TableIdForPrefs) {
    let v = this.localStorage.getItem(this.documentTablePreferences + "-" + key);
    if (v) {
      return JSON.parse(v) as any as DocumentTablePreferences;
    }
    switch(key){
      case TableIdForPrefs.INBOX:
        return {
          pageSize: 10,
          hiddenColumns: {
            title: false,
            date: false,
            topic: false,
            source: false,
            type: false,
            tickers: false,
            pageCount: false,
            notes: false,
            readStatus: true,
            actions: false,
            status: true
          }
        };
      case TableIdForPrefs.TOPIC_DOCUMENT_LIST:
        return {
          pageSize: 10,
          hiddenColumns: {
            title: false,
            date: false,
            topic: true,
            source: false,
            type: false,
            tickers: false,
            pageCount: false,
            notes: false,
            readStatus: true,
            actions: false,
            status: true
          }
        };
    };
  }

  private saveDocumentTablePreferences(key: string, d: DocumentTablePreferences) {
    this.localStorage.setItem(this.documentTablePreferences + "-" + key, JSON.stringify(d));
  }

  createRecursiveProxy(target: any, cb: () => void): any {
    // If the target is not an object or is null, no need to create a proxy
    if (typeof target !== 'object' || target === null) {
      return target;
    }

    const handler = {
      get: (obj: any, prop: string) => {
        const value = obj[prop];
        // If the property value is an object, wrap it in a proxy recursively
        if (typeof value === 'object' && value !== null) {
          return this.createRecursiveProxy(value, cb);
        }
        return value;
      },
      set: (obj: any, prop: string, value: any) => {
        obj[prop] = value; // Update the property value
        cb();
        return true;
      }
    };

    // Create and return a Proxy with the recursive handler
    return new Proxy(target, handler);
  }

}
