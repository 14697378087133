import {Component, effect, Injector} from '@angular/core';
import {PaginatedDataProviderService} from "../../../services/paginated-data-provider.service";
import {DocumentService, DocumentWebDto, SearchResponse} from "../../../../openapi-generated";
import {BasePageComponent} from "../../../base-component/base-page/base-page.component";
import {
  DocumentDatagridService,
  DocumentListRequest, TABLE_ID_FOR_PREFS
} from "../../../components/document-datagrid/document-datagrid.service";
import {TitleService} from "../../../services/title.service";
import {TableIdForPrefs} from "../../../services/preferences.service";

@Component({
  selector: 'app-topic-documents-page',
  templateUrl: './topic-documents-page.component.html',
  styleUrl: './topic-documents-page.component.scss',
  providers: [DocumentDatagridService,
    {
      provide: TABLE_ID_FOR_PREFS,
      useValue: TableIdForPrefs.TOPIC_DOCUMENT_LIST
    }]
})
export class TopicDocumentsPageComponent extends BasePageComponent {
  ready: boolean = false;


  constructor(injector: Injector, private documentService: DocumentService, public documentDatagridService: DocumentDatagridService, private titleService: TitleService) {
    super(injector);
    effect(() => {
      this.titleService.pagePrefix.set(this.topicSummaryState().data?.name || '');
    }, {allowSignalWrites: true});
    effect(() => {
      if(this.routeParams().topicId) {
        // Bootstrap only when topic id is available
        this.documentDatagridService.filterFormGroup.controls.topicId.setValue(this.routeParams().topicId!);
        const dataProvider = new PaginatedDataProviderService({
          fetch: (request: DocumentListRequest) => {
            return this.documentService.searchDocuments({
              searchProvider: "TRIEVE",
              ...request
            });
          },
          extractTotalCount: result => result.totalCount,
          extractDataArray: result => result.items
        });
        this.documentDatagridService.registerRefresh(dataProvider);
        this.ready = true;
      }
    });

  }

}
