import {Component, Input, OnInit, signal, WritableSignal} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-display-document-text',
  templateUrl: './display-document-text.component.html',
  styleUrl: './display-document-text.component.scss'
})
export class DisplayDocumentTextComponent implements OnInit {
  @Input() src!: string;

  text: WritableSignal<string> = signal('Downloading...');

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
    this.http.get(this.src, {responseType: "text"}).subscribe(it => this.text.set(it));
  }


}
