import {Component, Injector} from '@angular/core';
import {BasePageComponent} from "../../../base-component/base-page/base-page.component";
import {PaginatedDataProviderService} from "../../../services/paginated-data-provider.service";
import {InboxService} from "../../../../openapi-generated";
import {
  DocumentDatagridService,
  DocumentListRequest,
  TABLE_ID_FOR_PREFS
} from "../../../components/document-datagrid/document-datagrid.service";
import {TableIdForPrefs} from "../../../services/preferences.service";

@Component({
  selector: 'app-inbox-page',
  templateUrl: './inbox-page.component.html',
  styleUrl: './inbox-page.component.scss',
  providers: [DocumentDatagridService,
    {
      provide: TABLE_ID_FOR_PREFS,
      useValue: TableIdForPrefs.INBOX
    }]
})
export class InboxPageComponent extends BasePageComponent {


  constructor(injector: Injector,
              inboxService: InboxService,
              public documentDatagridService: DocumentDatagridService) {
    super(injector);

    const dataProvider = new PaginatedDataProviderService({
      fetch: (request: DocumentListRequest) => {
        return inboxService.inbox(request);
      },
      extractDataArray: r => r.items,
      extractTotalCount: result => result.totalCount
    });
    this.documentDatagridService.registerRefresh(dataProvider);
  }

}
