import { Injectable } from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class NavigationFromRowService {

  constructor(private _router: Router) { }

  navigateOnClickFromTableRow(args: string[], $event: MouseEvent) {
    // Walk the ancestors till the clr-dg-row
    let currentElement: HTMLElement | null = $event.target as HTMLElement;
    const tagPath = [];

    while (currentElement && !(currentElement.tagName.toLowerCase() === "clr-dg-row")) {
      tagPath.push(currentElement.tagName.toLowerCase());
      if(currentElement.attributes.getNamedItem("data-clickable")){
        return;
      }
      currentElement = currentElement.parentElement;
    }
    // We don't navigate if the clicked element is one with typically a click handler
    if (!this.intersects(["a", "button", "label", "input"], tagPath)) {
      this._router.navigate(args);
    }
  }

  intersects<T>(arr1: T[], arr2: T[]): boolean {
    const set2 = new Set(arr2);
    return arr1.filter(item => set2.has(item)).length > 0;
  }
}
