<nav class="subnav">
  <ul class="nav">
    <li class="nav-item" data-test="subnav-breadcrumb">
      <a class="nav-link " routerLinkActive="active" routerLink="/topic/list" data-test="navbar-all-topics">All topics</a>
      <cds-icon shape="angle" direction="right"></cds-icon>
      @if (topicSummaryState().isReady) {
        <a class="nav-link"  data-test="navbar-current-topic"
           routerLink="/topic/{{routeParams().topicId}}/document">
          <cds-icon shape="view-list"></cds-icon>
          {{ topicSummaryState().data!.name }}</a>

      }
      @if (topicSummaryState().isLoading) {
        <cds-icon shape="loading"></cds-icon>
      }
      <cds-icon shape="angle" direction="right"></cds-icon>
      <a class="nav-link only-show-active"
         routerLinkActive="active"
         data-test="navbar-current-topic-documents"
         routerLink="/topic/{{routeParams().topicId}}/document">
        <cds-icon shape="view-list"></cds-icon>
        Documents</a>
      <a class="nav-link only-show-active"
         routerLinkActive="active"
         data-test="navbar-current-topic-edit"
         routerLink="/topic/{{ routeParams().topicId}}/edit">
        <cds-icon shape="pencil"></cds-icon>
        Edit topic</a>
      <a class="nav-link only-show-active"
         routerLinkActive="active"
         data-test="navbar-current-topic-add-document"
         routerLink="/topic/{{ routeParams().topicId}}/add-document">
        <cds-icon shape="plus"></cds-icon>
        Add documents</a>
    </li>
    <li class="filler"></li>
    <li class="nav-item" data-test="subnav-siblings">
      <a class="nav-link" routerLinkActive="active"
         data-test="navbar-current-topic-documents"
         routerLink="/topic/{{routeParams().topicId}}/document">
        <cds-icon shape="view-list"></cds-icon>
        Document list</a>

      <a class="nav-link" routerLinkActive="active"
         data-test="navbar-current-topic-edit"
         routerLink="/topic/{{ routeParams().topicId}}/edit">
        <cds-icon shape="pencil"></cds-icon>
        Edit this topic</a>
      <a class="nav-link" routerLinkActive="active"
         data-test="navbar-current-topic-add-document"
         routerLink="/topic/{{ routeParams().topicId}}/add-document">
        <cds-icon shape="plus"></cds-icon>
        Add documents</a>
    </li>
  </ul>
</nav>
