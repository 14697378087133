import {signal, WritableSignal} from '@angular/core';
import {TemplatePortal} from "@angular/cdk/portal";

export class PortalService {

  constructor() {
  }

  subnav: WritableSignal<TemplatePortal | null> = signal(null);
  mainnav: WritableSignal<TemplatePortal | null> = signal(null);
  notifications: WritableSignal<TemplatePortal | null> = signal(null);

}
