import {Component, effect, Injector, signal, WritableSignal} from '@angular/core';
import {BasePageComponent} from "../../../base-component/base-page/base-page.component";
import {createHttpState, HttpStateStatus, HttpStateWrapper} from "../../../services/http-state.service";
import {DocumentService, DocumentWebDto} from "../../../../openapi-generated";
import {TitleService} from "../../../services/title.service";

@Component({
  selector: 'app-document-detail-page',
  templateUrl: './document-detail-page.component.html',
  styleUrl: './document-detail-page.component.scss'
})
export class DocumentDetailPageComponent extends BasePageComponent {

  state: WritableSignal<HttpStateWrapper<DocumentWebDto>> = signal(createHttpState());

  constructor(injector: Injector, private documentService: DocumentService, private titleService: TitleService) {
    effect(() => {
      if (this.routeParams().documentId && this.state().status === HttpStateStatus.NOT_STARTED) {
        this.refresh();
      }
    }, {allowSignalWrites: true});
    effect(() => {
      this.titleService.pagePrefix.set(this.state().data?.title || '');
    }, {allowSignalWrites: true})
    super(injector);
  }

  private refresh() {
    this.state.set(createHttpState());
    this.documentService.fetchDocumentDto(this.routeParams().documentId!).subscribe(state => {
      this.state.set(state);
    });
  }
}
