import {Component, effect, Injector, signal, ViewChild, WritableSignal} from '@angular/core';
import {BasePageComponent} from "../../../base-component/base-page/base-page.component";
import {createHttpState, HttpStateStatus, HttpStateWrapper} from "../../../services/http-state.service";
import {DocumentNote, DocumentService, DocumentWebDto, NoteService} from "../../../../openapi-generated";
import {IdentityService} from "../../../services/identity.service";
import {IntersectionObserverDirective} from "../../../viewport/intersection-observer.directive";
import {TitleService} from "../../../services/title.service";


export enum NotesPageScrollContainer {
  NONE,
  PDF,
  NOTES
}

@Component({
  selector: 'app-document-note-taking-page',
  templateUrl: './document-note-taking-page.component.html',
  styleUrl: './document-note-taking-page.component.scss'
})
export class DocumentNoteTakingPageComponent extends BasePageComponent {


  state: WritableSignal<HttpStateWrapper<DocumentWebDto>> = signal(createHttpState());
  noteState: WritableSignal<HttpStateWrapper<DocumentNote[]>> = signal(createHttpState());
  deleteState: WritableSignal<HttpStateWrapper<void>> = signal(createHttpState());
  currentPage: number = 1;

  whoIsDrivingScroll = signal(NotesPageScrollContainer.NONE);

  myNotesOnly: WritableSignal<boolean> = signal(false);

  editMode: WritableSignal<boolean> = signal(false);

  notes: WritableSignal<DocumentNote[]> = signal([]);

  @ViewChild('scrollContainer')
  scrollContainer?: IntersectionObserverDirective;

  constructor(injector: Injector, private documentService: DocumentService, private noteService: NoteService, public identityService: IdentityService, private titleService: TitleService) {
    effect(() => {
      if (this.routeParams().documentId && this.state().status === HttpStateStatus.NOT_STARTED) {
        this.refresh();
      }
    }, {allowSignalWrites: true});
    effect(() => {
      this.titleService.pagePrefix.set(this.state().data?.title || '');
    }, {allowSignalWrites: true})
    super(injector);
    effect(() => {
      if (!this.noteState().isReady) {
        this.notes.set([]);
      } else {
        if (this.myNotesOnly()) {
          this.notes.set(this.noteState().data!.filter(it => it.authorEmail === this.identityService.whoami()?.email));
        } else {
          this.notes.set(this.noteState().data!);
        }
      }
    }, {allowSignalWrites: true});
  }

  private refresh() {
    this.state.set(createHttpState());
    this.documentService.fetchDocumentDto(this.routeParams().documentId!).subscribe(state => {
      this.state.set(state);
    });
    this.refreshNotes();
  }

  protected refreshNotes(scrollToNoteId?: string) {
    this.noteState.set(createHttpState());
    this.noteService.getDocumentNotes(this.routeParams().documentId!).subscribe(state => {
      this.noteState.set(state);
      if (state.isReady && state.data?.length === 0) {
        this.editMode.set(true);
      } else if (state.isReady && scrollToNoteId) {
        this.scrollNoteIntoView(scrollToNoteId);
      }
    });
  }

  addNote() {
    this.editMode.set(true);
  }

  removeNote(note: DocumentNote, previousNote?: DocumentNote) {
    this.noteService.deleteNote(note).subscribe(state => {
      this.deleteState.set(state);
      if (state.isReady) {
        this.refreshNotes(previousNote?.id);
      }
    });
  }

  setPage(page: number) {
    this.currentPage = page;
  }

  onPdfPageChange(newPage: number) {
    if (this.noteState().isReady && this.whoIsDrivingScroll() === NotesPageScrollContainer.PDF) {
      let notesAfterThisPage = this.noteState().data!.filter(n => n.page >= newPage);
      if (notesAfterThisPage.length) {
        this.scrollNoteIntoView(notesAfterThisPage[0].id);
      }
    }
  }

  private scrollNoteIntoView(noteId: string) {
    // Because the view need to be rendered before we can scroll ...
    setTimeout(() => {
      const element = document.getElementById(noteId);
      if (element) {
        element.scrollIntoView({behavior: 'instant', block: 'center'});
      }
    });
  }


  elementViewportChange(note: DocumentNote, index: number, inViewport: boolean) {
    if (inViewport) {

      queueMicrotask(() => {
        if (this.whoIsDrivingScroll() === NotesPageScrollContainer.NOTES) {
          this.currentPage = note.page;
        }
      });
    }
  }

  editDone($event: { lastCreatedNoteId?: string }) {
    this.noteBeingEdited.set(undefined);
    this.editMode.set(false);
    if ($event.lastCreatedNoteId) {
      this.refreshNotes($event.lastCreatedNoteId);
    }
  }

  noteBeingEdited: WritableSignal<DocumentNote | undefined> = signal(undefined);
  notesPageScrollContainerEnum = NotesPageScrollContainer;

  editNote(note: DocumentNote) {
    this.currentPage = note.page;
    this.noteBeingEdited.set(note);
    this.editMode.set(true);
  }
}
