import {Component, Injector, signal, ViewChild, WritableSignal} from '@angular/core';
import {BasePageComponent} from "../../../base-component/base-page/base-page.component";
import {SecCompanyDescription, TopicService} from "../../../../openapi-generated";
import {createHttpState, HttpStateWrapper} from "../../../services/http-state.service";
import {Router} from "@angular/router";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {TopicFormService} from "../topic-form.service";
import {ClrForm} from "@clr/angular";

export interface TopicEditFormBasics {
  name: FormControl<string>,
  mailbox: FormControl<string>,
  defaultTags: FormControl<string[]>
}

export interface TopidEditFormCompanyGenericRetrieval {
  active: FormControl<boolean>;
  startDate: FormControl<string>,
  companyUrl: FormControl<string>
}

export interface TopicEditFormSecRetrieval {
  active: FormControl<boolean>,
  startDate: FormControl<string>,
  cik: FormControl<SecCompanyDescription[]>
}

export interface TopicEditFormCompany {
  identifier: FormControl<string>,
  name: FormControl<string>,
  defaultTags: FormControl<string[]>,
  tickers: FormControl<string[]>,
  sec: FormGroup<TopicEditFormSecRetrieval>,
  bernstein: FormGroup<TopidEditFormCompanyGenericRetrieval>,
  thirdBridge: FormGroup<TopidEditFormCompanyGenericRetrieval>,
  tegus: FormGroup<TopidEditFormCompanyGenericRetrieval>
}

export interface TopicEditForm {
  basics: FormGroup<TopicEditFormBasics>,
  companies: FormArray<FormGroup<TopicEditFormCompany>>
  finalize: FormGroup
}


@Component({
  selector: 'app-create-topic-page',
  templateUrl: './create-topic-page.component.html',
  styleUrl: './create-topic-page.component.scss',
  providers: [
    TopicFormService
  ]
})
export class CreateTopicPageComponent extends BasePageComponent {

  submitState: WritableSignal<HttpStateWrapper<string>> = signal(createHttpState());

  formGroup: FormGroup<TopicEditForm>;

  constructor(injector: Injector, public topicFormService: TopicFormService, private topicService: TopicService, private router: Router) {
    super(injector);
    this.formGroup = this.topicFormService.createTopicForm();
  }

  createTopic() {
    this.submitState.set(createHttpState());
    let topicWebDto = this.topicFormService.createTopicWebDto(this.formGroup, '');
    this.topicService.createTopic(topicWebDto).subscribe(state => {
      this.submitState.set(state);
      if (state.isReady) {
        this.router.navigateByUrl("/topic/" + state.data! + "/document");
      }
    });
  }

  @ViewChild(ClrForm) clrForm: ClrForm | undefined;

  markAsTouched() {
    this.clrForm?.markAsTouched();
  }

}
