import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-navbar-search',
  templateUrl: './navbar-search.component.html',
  styleUrl: './navbar-search.component.scss'
})
export class NavbarSearchComponent {

  @Input()
  placeholder: string = "Search ...";

  @Input('appFormControl')
  formControl: FormControl = new FormControl('');

  @Output('submitted')
  submittedEventEmitter = new EventEmitter();
}
