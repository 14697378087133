import {Component} from '@angular/core';

@Component({
  selector: 'app-load-extension-page',
  templateUrl: './load-extension-page.component.html',
  styleUrl: './load-extension-page.component.scss'
})
export class LoadExtensionPageComponent {

}
