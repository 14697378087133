import {Component, Input, signal, WritableSignal} from '@angular/core';
import {ClrModal} from "@clr/angular";
import {createHttpState} from "../../../../services/http-state.service";
import {SecCompanyDescription, SecLookupService} from "../../../../../openapi-generated";
import {TopicEditFormCompany} from "../create-topic-page.component";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-topic-company-form',
  templateUrl: './topic-company-form.component.html',
  styleUrl: './topic-company-form.component.scss'
})
export class TopicCompanyFormComponent {

  @Input()
  appFormGroup!: FormGroup<TopicEditFormCompany>;


  secInput: string = '';

  secState = signal(createHttpState());


  openSecModal(secModal: ClrModal) {

    if (this.appFormGroup.value.tickers && this.appFormGroup.value.tickers.length) {
      this.secInput = this.appFormGroup.value.tickers![0];
    } else {
      this.secInput = '';
    }
    this.secSearch();
    secModal.open();
  }

  constructor(private secLookupService: SecLookupService) {
  }

  secSearch() {
    this.secState.set(createHttpState());
    if (this.secInput) {

      this.secLookupService.secSearchCompany({
        search: this.secInput
      }).subscribe(state => this.secState.set(state));
    }
  }

  addCik(cik: SecCompanyDescription) {
    const v = this.appFormGroup.value.sec?.cik || [];
    this.appFormGroup.controls.sec.controls.cik.setValue([...v, cik]);
  }

  availableTickers: WritableSignal<string[]> = signal([]);


  tickerChanged($event: string) {
    this.availableTickers.set([$event]);
  }
}
