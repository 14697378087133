<ng-template #subnav>
    <app-document-navbar></app-document-navbar>
</ng-template>
@if (state().isReady) {
    @if (state().data!.referenceInStorage!['PDF']) {

        <div class="clr-row" style="height: 100%;">
            <div class="clr-col-6" style="height: 100%;"
                 (mouseenter)="whoIsDrivingScroll.set(notesPageScrollContainerEnum.PDF)"
                 (mouseleave)="whoIsDrivingScroll.set(notesPageScrollContainerEnum.NONE)"
            >
                <div class="pdf-container">
                    <ngx-extended-pdf-viewer
                            data-test="pdf-viewer"
                            [showToolbar]="true"
                            [zoom]="'page-width'"
                            [contextMenuAllowed]="true"
                            [textLayer]="true"
                            [filenameForDownload]="state().data!.originalFileName"
                            language="en-UK"
                            [showDrawEditor]="false"
                            [showOpenFileButton]="false"
                            [showPresentationModeButton]="true"
                            [showPropertiesButton]="false"
                            [showInfiniteScrollButton]="false"
                            [showSecondaryToolbarButton]="true"
                            [showTextEditor]="false"
                            [showStampEditor]="false"
                            [showHighlightEditor]="false"
                            [showBookModeButton]="false"
                            [handTool]="false"
                            height="auto"
                            [(page)]="currentPage"
                            (pageChange)="onPdfPageChange($event)"
                            [src]="'/api/document/pdf/'+state().data!.documentId">
                    </ngx-extended-pdf-viewer>

                </div>
            </div>
            @if (editMode()) {
                <app-create-note [documentId]="routeParams().documentId!" [page]="currentPage" class="clr-col-6"
                                 [currentNote]="noteBeingEdited()"
                                 (done)="editDone($event)"></app-create-note>
            } @else {
                <div class="clr-col-6 notes-column">
                    @if (noteState().isLoading) {
                        <clr-spinner></clr-spinner>
                    }
                    @if (noteState().isError) {
                        Something went wrong ...
                    }
                    @if (noteState().isReady) {

                        <div class="card no-margin-top">
                            <div class="card-footer">
                                <button class="btn btn-primary" (click)="addNote()" data-test="add-note">
                                    <cds-icon shape="plus"></cds-icon>
                                    Add note
                                </button>
                                <div class="btn-group">
                                    <button class="btn" [disabled]="myNotesOnly()" (click)="myNotesOnly.set(true)"
                                            data-test="my-notes-only">
                                        <cds-icon shape="check" *ngIf="myNotesOnly()"></cds-icon>
                                        My notes only
                                    </button>
                                    <button class="btn" [disabled]="!myNotesOnly()" (click)="myNotesOnly.set(false)"
                                            data-test="everyones-notes">
                                        <cds-icon shape="check" *ngIf="!myNotesOnly()"></cds-icon>
                                        Everyone's notes
                                    </button>
                                </div>
                                <div class="clr-flex-fill"></div>
                                <button class="btn" (click)="refreshNotes()" data-test="refresh-notes">
                                    <cds-icon shape="refresh"></cds-icon>
                                    Refresh
                                </button>
                            </div>
                        </div>
                        <div class="scroll-container clr-flex-fill"
                             (mouseenter)="whoIsDrivingScroll.set(notesPageScrollContainerEnum.NOTES)"
                             (mouseleave)="whoIsDrivingScroll.set(notesPageScrollContainerEnum.NONE)"
                             #scrollContainer
                             appIntersectionObserver
                        >
                            @for (note of notes(); let i = $index; track note.id) {
                                <div class="card note" [id]="note.id"
                                     [class.current]="currentPage === note.page"
                                     [attr.data-test]="'note-'+i"
                                     (click)="setPage(note.page)" style="cursor: pointer"
                                     appIntersectionObserved
                                     (inViewport)="elementViewportChange(note, i, $event)">
                                    <div class="card-header" style="display: flex">
                                        <div class="card-title" data-test="note-title">
                                            Note # {{ i }} Page {{ note.page }} by {{ note.authorEmail }}
                                            on {{ note | objectDate }}
                                        </div>
                                        <div class="clr-flex-grow-1"></div>
                                        <button *ngIf="identityService.whoami()?.email === note.authorEmail"
                                                class="btn btn-sm"
                                                data-test="edit-note"
                                                (click)="editNote(note)">
                                            <cds-icon shape="pencil"></cds-icon>
                                            Edit this note
                                        </button>
                                        <button *ngIf="identityService.whoami()?.email === note.authorEmail"
                                                data-test="remove-note"
                                                class="btn btn-sm btn-outline-danger"
                                                (click)="removeNote(note, i>0?notes()[i-1]:undefined)">
                                            <cds-icon shape="times"></cds-icon>
                                            Remove this note
                                        </button>
                                    </div>
                                    <div class="card-block" data-test="note-content">
                                        <app-note-content-display
                                                [html]="note.html"></app-note-content-display>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>

            }
        </div>
    }
}
