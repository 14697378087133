<div class="card">
  <div class="card-header">
    What can this app do ?
  </div>
  <div class="card-block">
    <p>
      A <b>topic</b> is an area of research and analysis, for instance <em>European automotive part suppliers</em>
    </p>
    <p>
      A topic fullfills multiple needs:</p>
    <ul>
      <li>Supporting the retrieval and centralization of documents:
        <ul>
          <li>Automated ingestion of documents from whichever source we've integrated (e.g. SEC filings)</li>
          <li>Simplified ingestion of documents from emails</li>
          <li>Simplified ingestion of Youtube transcripts</li>
          <li>...</li>
        </ul>
      </li>
      <li>Search, tag and filter documents, supported by AI</li>
    </ul>

    <p>
      This app helps as well track the reading of documents:
    </p>
    <ul>
      <li>Which document did you read already, when and up to which page</li>
      <li>What are the next documents to read for this topic</li>
    <li>Centralize comments and annotations on documents</li>
    </ul>
  </div>
</div>


<div class="card">
  <div class="card-header">
    You can ingest documents by just sending an email
  </div>
  <div class="card-block">
    Each topic as a corresponding <b>mailbox</b>. CC the mailbox, and the content of the email will be turned into a nice PDF document, and the attached PDFs will be ingested as well.
  </div>
</div>

<div class="card">
  <div class="card-header">
    You can ingest any webpage, even paywalled
  </div>
  <div class="card-block">
    To ingest a web page, you need to install a Chrome Extension, that'll allow you to grab the content of whichever website you visit, paywalled or not.
  </div>
  <div class="card-footer">
    <a class="btn" routerLink="/load-extension" data-test="chrome-extension-link">Chrome extension installation procedure</a>
  </div>

</div>
