<ng-template #subnav>
  <app-document-navbar></app-document-navbar>
</ng-template>
<div class="pdf-container" style="margin-right: -1.2rem;margin-left: -1.2rem;margin-top: -1.2rem;margin-bottom: -1.2rem">
    @if (state().isReady) {
      @if (state().data!.referenceInStorage!['PDF']) {
        <ngx-extended-pdf-viewer
          [showToolbar]="true"
          [zoom]="'page-width'"
          [contextMenuAllowed]="true"
          [textLayer]="true"
          [filenameForDownload]="state().data!.originalFileName"
          language="en-UK"
          [showDrawEditor]="false"
          [showOpenFileButton]="false"
          [showPresentationModeButton]="true"
          [showPropertiesButton]="false"
          [showInfiniteScrollButton]="false"
          [showSecondaryToolbarButton]="true"
          [showTextEditor]="false"
          [showStampEditor]="false"
          [showHighlightEditor]="false"
          [showBookModeButton]="false"
          [handTool]="false"
          height="auto"
          [src]="'/api/document/pdf/'+state().data!.documentId">
        </ngx-extended-pdf-viewer>
      } @else if (state().data!.referenceInStorage!['TEXT']) {
        <app-display-document-text data-test="text-content"
                                   [src]="'/api/document/text/'+state().data!.documentId"></app-display-document-text>
      }
    }
</div>
