import {Component} from '@angular/core';
import {BasePageComponent} from "../../../base-component/base-page/base-page.component";

@Component({
  selector: 'app-document-navbar',
  templateUrl: './document-navbar.component.html',
  styleUrl: './document-navbar.component.scss'
})
export class DocumentNavbarComponent extends BasePageComponent {

}
