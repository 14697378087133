import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrl: './dropzone.component.scss'
})
export class DropzoneComponent {

  @Input('appFormControl')
  appFormControl!: FormControl<FileList | null>

  onDrop($event: DragEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    const files = $event.dataTransfer?.files;
    if (files) {
      const df = new DataTransfer();
      if (this.appFormControl.value) {
        for (let i = 0; i < this.appFormControl.value.length; i++) {
          df.items.add(this.appFormControl.value[i]);
        }
      }
      for (let i = 0; i < files.length; i++) {
        df.items.add(files[i]);
      }
      this.appFormControl.setValue(df.files);
    }
  }

  onDragOver($event: DragEvent) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  protected readonly Array = Array;

  remove(f: File) {
    const df = new DataTransfer();
    for (let i = 0; i < this.appFormControl.value!.length; i++) {
      if (this.appFormControl.value![i] !== f) {
        df.items.add(this.appFormControl.value![i]);
      }
    }
    this.appFormControl.setValue(df.files);
  }

  removeAll() {
    const df = new DataTransfer();
    this.appFormControl.setValue(df.files);
  }
}
