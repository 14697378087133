import { Component } from '@angular/core';

@Component({
  selector: 'app-bookmark-list-page',
  templateUrl: './bookmark-list-page.component.html',
  styleUrl: './bookmark-list-page.component.scss'
})
export class BookmarkListPageComponent {

}
