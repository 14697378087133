import {Component, EventEmitter, Output, signal, WritableSignal} from '@angular/core';

export interface ScreenshotResult {
  taken: boolean,
  imageDataUrl?: string,
  text?: string
}

@Component({
  selector: 'app-take-pdf-screenshot',
  templateUrl: './take-pdf-screenshot.component.html',
  styleUrl: './take-pdf-screenshot.component.scss'
})
export class TakePdfScreenshotComponent {


  @Output()
  done: EventEmitter<ScreenshotResult> = new EventEmitter<ScreenshotResult>();

  closed: WritableSignal<boolean> = signal(false);

  selectionCoordinates: WritableSignal<{ x: number, y: number }[]> = signal([]);


  highlightInfo: WritableSignal<{
    startX: number;
    startY: number;
    width: number;
    height: number;
  } | null> = signal(null);

  grabbing: WritableSignal<boolean> = signal(false);

  startSelection($event: MouseEvent) {
    this.selectionCoordinates.set([{
      x: $event.pageX,
      y: $event.pageY
    }]);
    this.grabbing.set(true);
  }


  mouseMove($event: MouseEvent) {
    if (this.grabbing()) {
      const bothCoordinates = [this.selectionCoordinates()[0], {
        x: $event.pageX,
        y: $event.pageY
      }];
      const xx = bothCoordinates.map(it => it.x).sort((a, b) => a - b);
      const yy = bothCoordinates.map(it => it.y).sort((a, b) => a - b);
      this.highlightInfo.set({
        startX: xx[0],
        startY: yy[0],
        width: xx[1] - xx[0],
        height: yy[1] - yy[0]
      });
    }
  }

  endSelection() {
    this.grabbing.set(false);
    this.saveScreenshot();
  }

  resetSelection() {
    this.selectionCoordinates.set([]);
    this.highlightInfo.set(null);
  }

  saveScreenshot() {
    const allCanvas = Array.from(document.getElementsByTagName('canvas'));
    let cc = this.highlightInfo()!;
    const screenshotRect = new DOMRect(cc.startX, cc.startY, cc.width, cc.height);
    let matchingCanvas = allCanvas.filter(canvas => this.areRectangleIntersecting(screenshotRect, canvas.getBoundingClientRect()));

    if (cc.width < 1 || cc.height < 1) {
      console.log("Empty selection");
      this.resetSelection();
      return;
    }
    if (matchingCanvas.length === 0) {
      console.log("No matching canvas");
      this.resetSelection();
      return;
    }
    if (matchingCanvas.length > 1) {
      console.log("Matching multiple pages, not allowed");
      this.resetSelection();
      return;
    }
    const canvas = matchingCanvas[0];
    const canvasRect = canvas.getBoundingClientRect();
    const ctx = canvas.getContext('2d')!;
    const imageData = ctx.getImageData(cc.startX - canvasRect.x, cc.startY - canvasRect.y, cc.width, cc.height);

    // Create a new canvas to hold the subset
    const subsetCanvas = document.createElement('canvas');
    subsetCanvas.width = cc.width;
    subsetCanvas.height = cc.height;
    const subsetCtx = subsetCanvas.getContext('2d')!;

    // Put the image data onto the new canvas
    subsetCtx.putImageData(imageData, 0, 0);

    // Convert the new canvas to a PNG data URL
    const pngDataUrl = subsetCanvas.toDataURL('image/png');

    let imgBase64 = pngDataUrl;
    const textNodes = Array.from(document.querySelectorAll('.textLayer *'));
    let text = textNodes.filter(it => this.areRectangleIntersecting(screenshotRect, it.getBoundingClientRect())).map(el => el.textContent).join(" ");

    this.closed.set(true);
    this.done.emit({
      taken: true,
      imageDataUrl: imgBase64,
      text: text
    });
  }

  private areRectangleIntersecting(rect1: DOMRect, rect2: DOMRect) {
    return !(
      rect1.right < rect2.left ||
      rect1.left > rect2.right ||
      rect1.bottom < rect2.top ||
      rect1.top > rect2.bottom
    );
  }

  cancel() {
    this.closed.set(true);
    this.done.emit({
      taken: false
    });
  }
}
