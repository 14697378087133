<div class="main-container" [class.request-in-flight]="httpStateService.inFlightCount() > 0">
  @if (!identityService.whoami()) {
    <div class="center-container">
      <clr-spinner>Loading data</clr-spinner>
    </div>
  } @else if (!identityService.whoami()?.authenticated) {
    <div class="center-container">
      <a href="/oauth2/authorization/gitlab" class="btn btn-primary" data-test="login">Login</a>
    </div>
  } @else if (!identityService.whoami()?.authorized) {
    <div class="center-container" data-test="not-authorized">
      <div>
        <h1>User not authorized</h1>
        <p>Sorry, {{ identityService.whoami()?.email }} has no access to this app, please contact the administrator to request
          access.</p>
      </div>
    </div>
  } @else {
    <header class="header">
      <a routerLink="/home" class="branding">
        <a class="logo-and-title">
          <cds-icon shape="library"></cds-icon>
          <span class="title">Advaya Search</span>
        </a>
      </a>
      <div class="header-nav">
        <div>
          <a routerLink="/topic/list" routerLinkActive="active" class="nav-link nav-icon" data-test="topic-list">
            <cds-icon shape="file-group"></cds-icon> <span class="nav-text">Topics</span>
          </a>
          <a routerLink="/bookmarks" routerLinkActive="active" class="nav-link nav-icon" data-test="bookmarks">
            <cds-icon shape="bookmark"></cds-icon> <span class="nav-text">Bookmarks</span>
          </a>
          <a routerLink="/inbox" routerLinkActive="active" class="nav-link nav-icon" data-test="inbox">
            <cds-icon shape="inbox"></cds-icon> <span class="nav-text">Inbox</span>
          </a>
        </div>
        <ng-container [cdkPortalOutlet]="portalService.mainnav()"></ng-container>
      </div>
      <div class="header-actions">
        <clr-dropdown>
          <button class="nav-text" clrDropdownTrigger aria-label="open user profile" data-test="user-dropdown">
            <cds-icon shape="user"></cds-icon>&nbsp;{{ identityService.whoami()!.email }}
            <cds-icon shape="angle" direction="down"></cds-icon>
          </button>
          <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
            <form action="/logout" method="POST" #logoutForm></form>
            <button (click)="logoutForm.submit()" href="/logout" clrDropdownItem data-test="logout">Log out</button>
          </clr-dropdown-menu>
        </clr-dropdown>
      </div>

    </header>
    <div class="notification-slot">
      <div class="notification-container">
        <ng-container [cdkPortalOutlet]="portalService.notifications()"></ng-container>
      </div>
    </div>
    <ng-container [cdkPortalOutlet]="portalService.subnav()"></ng-container>
    <div class="content-container">
      <div class="content-area">
        <router-outlet></router-outlet>
      </div>
    </div>
  }
</div>
