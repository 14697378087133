<ng-template #subnav>
  <nav class="subnav" data-test="subnav">
    <ul class="nav">
      <li class="nav-item">
        <a class="nav-link " routerLinkActive="active" routerLink="/topic/list">All topics</a>
      </li>
      <li class="filler"></li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/topic/create" data-test="create-topic">
          <cds-icon shape="plus"></cds-icon>
          Create new topic</a>
      </li>
    </ul>
  </nav>
</ng-template>

<ng-template #mainnav>
  <app-navbar-search data-test="topic-search" [appFormControl]="topicSearchFormControl"
                     placeholder="Search for topics..."></app-navbar-search>
</ng-template>

<ng-template #notifications>
  @if (displayEmailCopyNotification()) {
    <clr-alert [clrAlertType]="'info'" (clrAlertClosedChange)="displayEmailCopyNotification.set('')">
      <clr-alert-item>
        <span data-test="notification-content"
              class="alert-text"><em>{{ displayEmailCopyNotification() }}</em> successfully copied to the clipboard.</span>
      </clr-alert-item>
    </clr-alert>
  }
</ng-template>


<clr-datagrid (clrDgRefresh)="queueRefresh()" [clrDgLoading]="dataProvider.loading()"
              data-test="topic-list-grid" style="height: 100%">
  <clr-dg-column>Name</clr-dg-column>
  <clr-dg-column>Email</clr-dg-column>
  <clr-dg-row *ngFor="let topic of dataProvider.currentPage();index as i" [attr.data-test]="'row-'+i"
              (click)="navigateOnClickFromTableRow(['topic',topic.id,'document'], $event)" style="cursor: pointer">
    <clr-dg-cell data-test="topic-name-cell">
      {{ topic.name }}
    </clr-dg-cell>
    <clr-dg-cell data-test="topic-mailbox-cell">
      <a (click)="copyEmailLink(topic.mailbox)" data-test="copy-link">
        <cds-icon shape="copy"></cds-icon>
      </a>
      {{ topic.mailbox }}&#64;{{ config.emailDomain }}

    </clr-dg-cell>
  </clr-dg-row>
  <clr-dg-placeholder>
    @if (dataProvider.state().isError) {
      Something broke ...
    }
    @if (dataProvider.state().isReady) {
      @if (topicSearchFormControl.value) {
        No matching topics ...
      } @else {
        <a class="btn btn-link" routerLinkActive="active" routerLink="/topic/create"
           data-test="create-new-topic-from-placeholder">
          <cds-icon shape="plus"></cds-icon>
          Create new topic</a>
      }
    }
  </clr-dg-placeholder>

  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10" data-test="pagination" [clrDgTotalItems]="dataProvider.total()">
      <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">Topics per page</clr-dg-page-size>
      <span data-test="pagination-text">{{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of
        {{ pagination.totalItems }} topics</span>
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>

