<clr-datagrid (clrDgRefresh)="s.queueRefresh($event)" [clrDgLoading]="s.dataProvider.loading()"
              [(clrDgSelected)]="s.selected"
              data-test="inbox-grid">

  <clr-dg-action-bar class="no-margin-top" style="min-height: 43px">
    <!-- force height, as the comboxbox height changes when it gets data -->

    <div class="clr-row clr-align-items-end">
      <div class="clr-col-auto">
        <app-tags-input [attr.data-test]="'tags-input'"
                        [tagsFormControl]="s.filterFormGroup.controls.tags"
                        label="Filter by tags"></app-tags-input>
      </div>
      <div class="clr-flex-grow-1"></div>
      <div class="clr-col-auto">
        @if (s.selected$().length) {
          <button class="btn btn-outline btn-sm" (click)="s.selected$.set([])"
                  data-test="clear-selection">
            <cds-icon shape="undo"></cds-icon>
            Clear selection
          </button>
          <app-get-link [ids]="s.pdfStorageIds()"
                        [message]="'Get PDF link'" data-test="get-pdf-link"></app-get-link>
          <button class="btn btn-danger-outline btn-sm" (click)="deleteTriggered = true" data-test="delete-documents">
            <cds-icon shape="times"></cds-icon>
            Delete documents<span
            class="badge badge-danger no-margin-right">{{ s.selected$().length }}</span></button>
          <button class="btn btn-sm btn-primary" (click)="s.markAllAsRead(s.selected$())"
                  data-test="mark-selection-as-read">
            <cds-icon shape="check"></cds-icon>

            Mark as read <span
            class="badge no-margin-right">{{ s.selected$().length }}</span> selected documents
          </button>
          <button class="btn btn-sm btn-outline" (click)="s.markAllAsUnread(s.selected$())"
                  data-test="mark-selection-as-unread">
            <cds-icon shape="check"></cds-icon>

            Mark as unread <span
            class="badge no-margin-right">{{ s.selected$().length }}</span> selected documents
          </button>
        }
        @if (s.selected$().length) {
          <button style="max-width: 80vh" class="btn btn-sm btn-outline" (click)="askQuestionsOnSelection()"
                  data-test="ask-on-selection">
            <cds-icon shape="unknown-status"></cds-icon>
            Ask questions on the <span
            class="badge no-margin-right">{{ s.selected$().length }}</span> selected documents
          </button>
        } @else {
          <button class="btn btn-sm btn-outline" (click)="askQuestionsOnThisResultSet()" data-test="ask-on-result-set">
            <cds-icon shape="unknown-status"></cds-icon>

            Ask questions on matching documents
          </button>
        }
        <button class="btn btn-sm btn-outline no-margin-right"
                (click)="s.dataProvider.loadData(s.makeRequest())"
                data-test="refresh">
          <cds-icon shape="refresh"></cds-icon>
          Refresh
        </button>
      </div>
    </div>
  </clr-dg-action-bar>


  <clr-dg-column class="title">Title</clr-dg-column>
  <clr-dg-column class="date">
    <ng-template clrDgHideableColumn [(clrDgHidden)]="s.tablePrefs.hiddenColumns.date">
      Date
    </ng-template>
  </clr-dg-column>
  <clr-dg-column class="topic">
    <ng-template clrDgHideableColumn [(clrDgHidden)]="s.tablePrefs.hiddenColumns.topic">
      Topic
    </ng-template>
  </clr-dg-column>
  <clr-dg-column class="source">
    <ng-template clrDgHideableColumn [(clrDgHidden)]="s.tablePrefs.hiddenColumns.source">
      Source
    </ng-template>
  </clr-dg-column>
  <clr-dg-column class="type">
    <ng-template clrDgHideableColumn [(clrDgHidden)]="s.tablePrefs.hiddenColumns.type">
      Type
    </ng-template>
  </clr-dg-column>
  <clr-dg-column class="tickers">
    <ng-template clrDgHideableColumn [(clrDgHidden)]="s.tablePrefs.hiddenColumns.tickers">
      Tickers
    </ng-template>
  </clr-dg-column>
  <clr-dg-column class="page-count">
    <ng-template clrDgHideableColumn [(clrDgHidden)]="s.tablePrefs.hiddenColumns.pageCount">
      # pages
    </ng-template>
  </clr-dg-column>
  <clr-dg-column class="notes">
    <ng-template clrDgHideableColumn [(clrDgHidden)]="s.tablePrefs.hiddenColumns.notes">
      Notes
    </ng-template>
  </clr-dg-column>
  <clr-dg-column class="read">
    <ng-template clrDgHideableColumn [(clrDgHidden)]="s.tablePrefs.hiddenColumns.readStatus">
      Read ?
    </ng-template>
  </clr-dg-column>
  <clr-dg-column class="actions">
    <ng-template clrDgHideableColumn [(clrDgHidden)]="s.tablePrefs.hiddenColumns.actions">
      Actions
    </ng-template>
  </clr-dg-column>
  <clr-dg-column>
    <ng-template clrDgHideableColumn [(clrDgHidden)]="s.tablePrefs.hiddenColumns.status">
      Status
    </ng-template>
  </clr-dg-column>

  <clr-dg-row *ngFor="let item of s.dataProvider.currentPage();index as i" [attr.data-test]="'row-'+i"
              (click)="navigationFromRowService.navigateOnClickFromTableRow(['topic',item.topicId,'document',item.documentId], $event)"
              [clrDgItem]="item"
              style="cursor: pointer" [class.read]="item.read">

    <clr-dg-cell class="title" data-test="title-cell">{{ item.title }}</clr-dg-cell>
    <clr-dg-cell class="date" data-test="date-cell">{{ item.date }}</clr-dg-cell>
    <clr-dg-cell class="topic" data-test="topic-name-cell">
      {{ item.topicName }}
    </clr-dg-cell>
    <clr-dg-cell class="source" data-test="doc-source-cell">
      <span data-clickable="true" class="label" *ngIf="item.source"
            [class.selected]="s.extractTags().indexOf(item.source) !==-1"
            (click)="s.toggleTag(item.source)"
            [attr.data-test]="item.source">{{ item.source.replace('source:', '') }}</span>
    </clr-dg-cell>
    <clr-dg-cell class="type" data-test="doc-type-cell">
      <span data-clickable="true" class="label" *ngIf="item.docType"
            [class.selected]="s.extractTags().indexOf(item.docType) !==-1"
            (click)="s.toggleTag(item.docType)"
            [attr.data-test]="item.docType">{{ item.docType.replace('doc-type:', '') }}</span>
    </clr-dg-cell>
    <clr-dg-cell class="tickers" data-test="tickers-cell">
      @if (item.tickers) {
        <span data-clickable="true" class="label" *ngFor="let it of item.tickers"
              [class.selected]="s.extractTags().indexOf(it) !==-1"
              (click)="s.toggleTag(it)"
              [attr.data-test]="it">{{ it.replace('ticker:', '') }}</span>
      }
    </clr-dg-cell>
    <clr-dg-cell class="page-count" data-test="page-count-cell">{{ item.pdfPageCount }}</clr-dg-cell>
    <clr-dg-cell class="notes" data-test="notes-cell">
      @for (n of item.readingSummaries; track n.email) {
        <span class="label"> {{ n.email.split('@')[0] }} <span class="badge">{{ n.noteCount }}</span></span>
      }
    </clr-dg-cell>
    <clr-dg-cell class="read" data-test="read-cell">
      @if (item.read) {
        <cds-icon shape="check"></cds-icon>
      } @else {
        <cds-icon shape="times"></cds-icon>
      }
    </clr-dg-cell>
    <clr-dg-cell class="actions" data-test="actions-cell">
      @if (item.read) {
        <button class="btn btn-sm" style="width: 100%" (click)="s.markAsUnread(item.documentId)">Mark as unread</button>
      } @else {
        <button class="btn btn-sm btn-primary" style="width: 100%" (click)="s.markAsRead(item.documentId)">Mark as
          read
        </button>
      }
    </clr-dg-cell>
    <clr-dg-cell>
      @if (taskSummary(item) == 'ERROR') {
        <span class="label label-danger">Error</span>
        <button class="btn btn-sm" (click)="retry(item.documentId!)" data-test="retry">Retry</button>
      }
      @if (taskSummary(item) == 'PENDING') {
        <span class="label label-info">In progress</span>
        <button class="btn btn-sm" (click)="retry(item.documentId!)" data-test="reschedule">Reschedule</button>
      }
      @if (taskSummary(item) == 'DONE') {
        <span class="label label-success" data-test="all-good">All good</span>
      }
    </clr-dg-cell>

<!--    @if(!!item.searchWebDto){-->
<!--      <clr-dg-row-detail *clrIfExpanded="!!item.searchWebDto">-->
<!--        <div data-test="row-detail">-->
<!--          @for (excerpts of item.searchWebDto!.excerpts; track excerpts) {-->
<!--            <div class="excerpt" [innerHTML]="domSanitizer.sanitize(SecurityContext.HTML, excerpts)"></div>-->
<!--          }-->
<!--        </div>-->
<!--      </clr-dg-row-detail>-->
<!--    }-->
  </clr-dg-row>
  <clr-dg-placeholder>
    @if (s.dataProvider.state().isError) {
      Something broke ...
    }
    @if (s.dataProvider.state().isReady) {
      @if (s.filterFormGroup.value.searchTerm && !s.filterFormGroup.value.tags?.length) {
        No matching documents ...
      } @else {
        No items ...
      }
    }
  </clr-dg-placeholder>

  <clr-dg-footer>
    <app-document-pagination></app-document-pagination>
  </clr-dg-footer>
</clr-datagrid>


<clr-modal [(clrModalOpen)]="deleteTriggered" [clrModalClosable]="false" data-test="delete-modal">
  <h3 class="modal-title">Do you really want to delete these documents</h3>
  <div class="modal-body" data-test="delete-modal-body">
    <ul>
      @for (doc of s.selected$(); track doc.documentId) {
        <li>{{ doc.title }} - {{ doc.date }}</li>
      }
    </ul>
  </div>
  <div class="modal-footer">
    <button [clrLoading]="deleteHttpState().isLoading" type="button" class="btn btn-outline"
            (click)="deleteTriggered = false" data-test="dont-delete">No, keep them
    </button>
    <button [clrLoading]="deleteHttpState().isLoading" type="button" class="btn btn-primary btn-danger"
            (click)="deleteSelection();" data-test="do-delete">
      Yes,
      delete them
    </button>
  </div>
</clr-modal>
