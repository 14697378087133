<ng-template #subnav>
  <app-topic-navbar></app-topic-navbar>
</ng-template>
@if (form && topicSummaryState().isReady) {
  <form [formGroup]="form" autocomplete="off" clrForm clrLayout="horizontal">

    <div class="card">
      <div class="card-header" data-test="add-document-header">Add document(s) to topic
        <em>{{ topicSummaryState().data?.name }}</em></div>
      <div class="card-block">
        <app-dropzone data-test="file-dropzone" [appFormControl]="form!.controls.files"></app-dropzone>
      </div>
      <div class="card-block">
        @if (mandatoryTags().isReady) {
          <clr-radio-container data-test="mandatory-tags" *ngFor="let tag of mandatoryTags().data!.tags;let i = index">
            <label [attr.data-test]="'mandatory-tag-'+tag.category">{{ tag.categoryDisplayName }}</label>
            <clr-radio-wrapper *ngFor="let choice of tag.choices">
              <input type="radio" clrRadio [name]="tag.category" [attr.data-test]="'radio-'+tag.category+'-'+choice.tagName"
                     [formControl]="form.controls.mandatoryTags.controls[i].controls.tagValue"
                     id="{{tag.category}}:{{choice.tagName}}"
                     value="{{choice.tagName}}"/>
              <label [attr.data-test]="choice.tagName" [attr.data-test]="'label-'+tag.category+'-'+choice.tagName"
                     for="{{tag.category}}:{{choice.tagName}}">{{ choice.tagDisplayName }}</label>
            </clr-radio-wrapper>
          </clr-radio-container>
        }
      </div>
      <div class="card-block">
        <app-tags-input data-test="additional-tags" [tagsFormControl]="form.controls.additionalTags"
                        label="Additional tags"></app-tags-input>
      </div>
      <div class="card-footer">
        <button [clrLoading]="submitHttpState().isLoading" class="btn btn-primary" [disabled]="form.invalid"
                data-test="send-files-button"
                (click)="submit()">Send
        </button>
        <a [clrLoading]="submitHttpState().isLoading" class="btn btn-outline" type="button"
           [routerLink]="'/topic/'+topicSummaryState().data?.id+'/document'"
           data-test="dont-send-files-button"
        >Don't send
        </a>
      </div>
    </div>

  </form>
} @else {
  <div class="center-container">
    <clr-spinner></clr-spinner>
  </div>
}
