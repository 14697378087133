<ng-template #subnav>
  <app-topic-navbar></app-topic-navbar>
</ng-template>

<ng-template #mainnav>
  <app-navbar-search data-test="topic-documents-searchbar" placeholder="Search for documents in this topic..."
                     [appFormControl]="documentDatagridService.filterFormGroup.controls.searchTerm"></app-navbar-search>
</ng-template>


@if (ready) {
  <app-document-datagrid></app-document-datagrid>
}
