<div class="card">
  <div class="card-header">Ask a question
  </div>
  @if (documentIds) {
    <div class="card-block">
      Searching on the {{ documentIds.length }} selected documents totalling {{ totalCharacters() }} characters:
      @if (documentsState().isLoading) {
        <clr-spinner clrSmall></clr-spinner>
      }
      @if (documentsState().isReady) {
        <ul data-test="selected-document-list">
          @for (doc of documentsState().data!; track doc.documentId) {
            <li>{{ doc.title }} ({{ doc.pdfPageCount }} pages and {{ doc.characterCount }} characters)</li>
          }
        </ul>
      }
    </div>
  }
  @if (searchCriteria) {
    @if (searchCriteria.searchTerm) {
      <div class="card-block" data-test="search-term-criteria">
        Search term: <b>{{ searchCriteria.searchTerm }}</b> was mentioned, but alas cannot considered for filtering
        documents for Q&amp;A
      </div>
    }
    @if (searchCriteria.tags && searchCriteria.tags.length) {
      <div class="card-block" data-test="tag-filter-criteria">
        The following tags will be used to filter the document set:

        @for (tag of searchCriteria.tags; track tag) {
          <span class="label">{{ tag }}</span>
        }
      </div>
    }
  }
  @if (documentIds && documentIds.length) {
    <div class="card-block">
      <div class="btn-group">
        <div class="radio btn">
          <input type="radio" [formControl]="providerFormControl" id="btn-demo-radio-1"
                 [value]="ChatProviderEnum.Trieve"/>
          <label for="btn-demo-radio-1">Trieve (full text search + RAG)</label>
        </div>
        <div class="radio btn">
          <input type="radio" [formControl]="providerFormControl" id="btn-demo-radio-2"
                 [value]="ChatProviderEnum.GeminiText"/>
          <label for="btn-demo-radio-2">Google Gemini (using text only)</label>
        </div>
        <div class="radio btn">
          <input type="radio" [formControl]="providerFormControl" id="btn-demo-radio-3"
                 [value]="ChatProviderEnum.GeminiPdf"/>
          <label for="btn-demo-radio-3">Google Gemini (using PDF files)</label>
        </div>
      </div>
    </div>
  }
  <div class="card-block" contenteditable="true" #question
       (keydown.enter)="submit(question.textContent!);$event.preventDefault();"
       (focus)="question.textContent==='type here...'?question.innerHTML='':''">
    <em>type here...</em>
  </div>
  @if (state().isLoading) {
    <div class="card-block" style="display: flex;justify-content: center;align-items: center;">
      <clr-spinner></clr-spinner>
    </div>
  }
</div>
@if (state().isReady) {
  <div class="card">
    <div class="card-block">
      <div style="white-space: pre-line;">
        {{ state().data!.answer }}
      </div>
    </div>
    <div class="card-block">
      <app-get-link [ids]="pdfStorageIds()"></app-get-link>
    </div>
  </div>
  @for (ref of state().data!.references; track ref.documentId) {
    <div class="card">
      <div class="card-header">
        {{ ref.documentDto.title }} - {{ ref.documentDto.date }}
      </div>
      <div class="card-block">
        {{ ref.documentDto.description }}
      </div>
      @for (html of ref.htmlExcerpts; track html) {
        <div class="card-block" [innerHTML]="domSanitizer.bypassSecurityTrustHtml(html!)"
             style="white-space: pre-line;">

        </div>
      }
    </div>
  }

}
