import { Component } from '@angular/core';
import {BasePageComponent} from "../../../base-component/base-page/base-page.component";

@Component({
  selector: 'app-topic-navbar',
  templateUrl: './topic-navbar.component.html',
  styleUrl: './topic-navbar.component.scss'
})
export class TopicNavbarComponent extends BasePageComponent{

}
