import {Component, Input, OnInit, signal, WritableSignal} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {TopicEditFormBasics} from "../create-topic-page.component";
import {config} from "../../../../config";
import {TagsService} from "../../../../../openapi-generated";

@Component({
  selector: 'app-topic-basics-form',
  templateUrl: './topic-basics-form.component.html',
  styleUrl: './topic-basics-form.component.scss'
})
export class TopicBasicsFormComponent implements OnInit{

  @Input()
  appFormGroup!: FormGroup<TopicEditFormBasics>

  availableTags_ = ['first', 'second']; // TODO load from backend
  availableTags: WritableSignal<any[]> = signal(this.availableTags_);

  constructor(private tagsService: TagsService) {
  }

  ngOnInit(): void {
        //this.tagsService().getTagChoices().subscribe(state => )
    }

  tagChanged($event: string) {
    if ($event.indexOf(":") !== -1) {
      this.availableTags.set([
        $event,
        ...this.availableTags_
      ]);
    } else {
      this.availableTags.set(this.availableTags_);
    }
  }

  protected readonly config = config;
}
