import {Component, signal} from '@angular/core';
import {ClrComboboxModule, ClrCommonFormsModule, ClrInputModule, ClrSpinnerModule} from "@clr/angular";
import {NgIf} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {DocumentService, LogService} from "../../../openapi-generated";
import {createHttpState} from "../../services/http-state.service";

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrl: './admin-page.component.scss'
})
export class AdminPageComponent {

  constructor(private documentService: DocumentService, private logService: LogService){}

  state = signal(createHttpState());
  trieveRefreshState = signal(createHttpState());

  retryAll(){
    this.state.set(createHttpState());
    this.documentService.retryAllDocumentTasks().subscribe(s => this.state.set(s));
  }

  refreshAllTrieveMetadata() {
    this.documentService.refreshAllTrieveMetadata().subscribe(s =>this.trieveRefreshState.set(s));
  }

  triggerFrontendError() {
    throw new Error("Manually triggered error");
  }

  triggerBackendError() {
    this.logService.triggerError().subscribe();
  }

  triggerBackendException() {
    this.logService.triggerException().subscribe();
  }

  triggerBackendWarning() {
    this.logService.triggerWarning().subscribe();
  }
}
