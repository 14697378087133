import { Component } from '@angular/core';
import {DocumentDatagridService} from "../../../components/document-datagrid/document-datagrid.service";

@Component({
  selector: 'app-document-pagination',
  templateUrl: './document-pagination.component.html',
  styleUrl: './document-pagination.component.scss'
})
export class DocumentPaginationComponent {

  constructor(public documentDatagridService: DocumentDatagridService){}
}
